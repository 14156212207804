import React, { useState, useEffect } from "react";
import BiHexSelection from "../../../components/OrderingSystemComponents/UniversalComponents/BiHexSelection";
import DimensionsSelection from "../../../components/OrderingSystemComponents/UniversalComponents/DimensionsSelection";
import PersonalInfoSelection from "../../../components/OrderingSystemComponents/UniversalComponents/PersonalInfoSelection";
import LoadSelection from "../../../components/OrderingSystemComponents/UniversalComponents/LoadSelection";
import PermitChoice from "../../../components/OrderingSystemComponents/PergolaPageComponents/PergolaPermitChoice";
import StyleChoices from "../../../components/OrderingSystemComponents/PergolaPageComponents/PergolasStyleChoices";
import SubmitButton from "../../../components/OrderingSystemComponents/PergolaPageComponents/PergolaSubmitButton";
import MotorCityDimensions from "../../../components/OrderingSystemComponents/PergolaPageComponents/MotorCityDimensions";
import SteelPiers from "../../../components/OrderingSystemComponents/UniversalComponents/PermitChoiceComponents/SteelPiers";
import PergolaGutterSelection from "../../../components/OrderingSystemComponents/PergolaPageComponents/PergolaStyleChoicesComponents/PergolaGutterChoice";
const Pergolas = () => {
  const [selectedTrimOption, setSelectedTrimOption] = useState("");
  const [selectedAttachedOption, setSelectedAttachedOption] = useState("");
  const [permitSelected, setPermitSelected] = useState("");
  const [selectedOperableOption, setSelectedOperableOption] = useState("");
  const [selectedExteriorOption, setSelectedExteriorOption] = useState("");
  const [selectedPostStyleOption, setSelectedPostStyleOption] = useState("");
  const [selectedBiHexOption, setSelectedBiHexOption] = useState("");
  const [selectedActuatorOption, setSelectedActuatorOption] = useState("");
  const [selectedPermitOption, setSelectedPermitOption] = useState("");
  const [selectedKneeWallOption, setSelectedKneeWallOption] = useState("");
  const [selectedFoundationOption, setSelectedFoundationOption] = useState("");
  const [selectedMountOption, setSelectedMountOption] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [sender, setSender] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [zip, setZip] = useState("");
  const [purchaseOrder, setPostBox] = useState("");
  const [
    selectedExistingFoundationOption,
    setSelectedExistingFoundationOption,
  ] = useState("");
  const [selectedNewFoundationOption, setSelectedNewFoundationOption] =
    useState("");
  const [gradeHeightFeet, setGradeHeightFeet] = useState("");
  const [gradeHeightInches, setGradeHeightInches] = useState("");
  const [stepDownInches, setStepDownInches] = useState("");
  const [stepDownFeet, setStepDownFeet] = useState("");
  const [selectedWindLoadChange, setSelectedWindLoadChange] = useState("");
  const [selectedRoofLoadChange, setSelectedRoofLoadChange] = useState("");
  const [widthFeet, setWidthFeet] = useState("");
  const [widthInches, setWidthInches] = useState("");
  const [lengthFeet, setLengthFeet] = useState("");
  const [lengthInches, setLengthInches] = useState("");
  const [selectedPostHeight, setSelectedPostHeight] = useState("");
  const [selectedKneeWallFeet, setSelectedKneeWallFeetChange] = useState("");
  const [selectedKneeWallInches, setSelectedKneeWallInchesChange] =
    useState("");
  const [selectedFasciaInches, setSelectedFasciaInches] = useState("");
  const [selectedBeamOption, setSelectedStructuralBeamOption] = useState("");
  const [selectedRafterColor, setRafterColorOptionChange] = useState("");
  const [fieldsWithRedBorder, setFieldsWithRedBorder] = useState([]);
  const [selectedGutterOption, setSelectedGutterOption] = useState("");
  const [, setKneeWallData] = useState({
    feet: "",
    inches: "",
  });
  const [selectedWidth, setSelectedWidth] = useState("");
  const [selectedLength, setSelectedLength] = useState("");
  const [initialSteelPiers, setInitialSteelPiers] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [locations, setLocations] = useState({});
  const [currentCADImagesFiles, setCurrentCADImagesFiles] = useState([]);
  const [drawingImages, setDrawingImages] = useState([]);

  const handleLocationChange = (event) => {
    var input = event.target.value;
    const updatedRedBorderFields = fieldsWithRedBorder.includes("location")
      ? input
        ? fieldsWithRedBorder.filter((field) => field !== "location")
        : fieldsWithRedBorder
      : input
        ? fieldsWithRedBorder
        : [...fieldsWithRedBorder, "location"];

    setFieldsWithRedBorder(updatedRedBorderFields);
    setSelectedLocation(input);
  };

  const validateEmail = (inputEmail) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(inputEmail);
    setIsValidEmail(isValid);
    return isValid;
  };

  const handleSteelPiers = (selectedSteelPiers) => {
    setInitialSteelPiers(selectedSteelPiers);
  };

  const handleMotorCityPergolaLength = (selected) => {
    setSelectedLength(selected);
  };

  const handleMotorCityPergolaWidth = (selected) => {
    setSelectedWidth(selected);
  };

  const handleRedBorderCheck = (missingFields) => {
    setFieldsWithRedBorder(missingFields);
  };

  const handlePersonalInfoChange = (info) => {
    setFirstName(info.firstName);
    setLastName(info.lastName);
    setSender(info.sender);
    setEmail(info.email);
    setAddress(info.address);
    setCity(info.city);
    setStateProvince(info.stateProvince);
    setZip(info.zip);
    setPostBox(info.purchaseOrder);
  };

  const handlePermitSelection = (selected) => {
    setPermitSelected(selected);

    if (!selected) {
      setGradeHeightFeet("");
      setGradeHeightInches("");
    }
  };

  const handleOperableOptionChange = (operableSelected) => {
    setSelectedOperableOption(operableSelected);
  };

  const handleStructuralBeamChange = (beamSelected) => {
    setSelectedStructuralBeamOption(beamSelected);
  };

  const handleAttachedOptionChange = (attachedSelected) => {
    setSelectedAttachedOption(attachedSelected);
  };

  const handleTrimOptionChange = (selectedOption) => {
    setSelectedTrimOption(selectedOption);
  };

  const handleRafterColorOptionChange = (selectedRColor) => {
    setRafterColorOptionChange(selectedRColor);
  };

  const handleExteriorOptionChange = (selectedExterior) => {
    setSelectedExteriorOption(selectedExterior);
  };

  const handlePostStyleOptionChange = (selectedPostStyle) => {
    setSelectedPostStyleOption(selectedPostStyle);
  };

  const handleBiHexOptionChange = (selectedBiHex) => {
    setSelectedBiHexOption(selectedBiHex);
  };

  const handleActuatorOptionChange = (selectedActuator) => {
    setSelectedActuatorOption(selectedActuator);
  };

  const handlePermitChange = (selectedPermit) => {
    setSelectedPermitOption(selectedPermit);
  };
  const handleKneeWallChange = (selectedKneeWall) => {
    if (selectedKneeWall === "no") {
      setKneeWallData({
        feet: "",
        inches: "",
      });
    }
    setSelectedKneeWallOption(selectedKneeWall);
  };
  const handleFoundationChange = (selectedFoundation) => {
    setSelectedFoundationOption(selectedFoundation);
  };
  const handleMountChange = (selectedMount) => {
    setSelectedMountOption(selectedMount);
    setSelectedFasciaInches("");
  };
  const handleExistingFoundationChange = (selectedExistingFoundation) => {
    setSelectedExistingFoundationOption(selectedExistingFoundation);
  };
  const handleNewFoundationChange = (selectedNewFoundation) => {
    setSelectedNewFoundationOption(selectedNewFoundation);
  };
  const handleGradeHeightOption = (feet, inches) => {
    setGradeHeightFeet(feet);
    setGradeHeightInches(inches);
  };
  const handleStepDownChange = (feet, inches) => {
    setStepDownFeet(feet);
    setStepDownInches(inches);
  };
  const handleWindLoadOption = (selectedWindLoad) => {
    setSelectedWindLoadChange(selectedWindLoad);
  };

  const handleRoofLoadOption = (selectedRoofLoad) => {
    setSelectedRoofLoadChange(selectedRoofLoad);
  };

  const handleKneeWallFeetChange = (selectedKneeWallFeetOption) => {
    setSelectedKneeWallFeetChange(selectedKneeWallFeetOption);
    if (selectedKneeWallOption === "no") {
      setSelectedKneeWallFeetChange("");
    }
  };

  const handleKneeWallInchesChange = (selectedKneeWallInchesOption) => {
    setSelectedKneeWallInchesChange(selectedKneeWallInchesOption);
    if (selectedKneeWallOption === "no") {
      setSelectedKneeWallInchesChange("");
    }
  };

  const handleDimensionChange = (feet, inches, type) => {
    if (type === "width") {
      setWidthFeet(feet);
      setWidthInches(inches);
    } else if (type === "length") {
      setLengthFeet(feet);
      setLengthInches(inches);
    }
  };

  const handlePostHeightOption = (selectedPostHeight) => {
    setSelectedPostHeight(selectedPostHeight);
  };

  const handleFasciaInchesOption = (
    selectedFasciaInchesOption,
    selectedMountOption,
  ) => {
    if (selectedMountOption === "A") {
      setSelectedFasciaInches(selectedFasciaInchesOption);
    } else {
      setSelectedFasciaInches("");
    }
  };

  const handlePergolaGutterChange = (selectedGutterOption) => {
    setSelectedGutterOption(selectedGutterOption);
  };

  useEffect(() => {
    if (selectedOperableOption === "MOTOR CITY") {
      setSelectedBiHexOption("");
      setSelectedExteriorOption("");
      setSelectedPostStyleOption("");
      setSelectedActuatorOption("");
      setSelectedAttachedOption("");
      setSelectedKneeWallOption("");
      setWidthFeet("");
      setWidthInches("");
      setLengthFeet("");
      setLengthInches("");
      setStepDownInches("");
      setStepDownFeet("");
      setSelectedPostHeight("");
      setSelectedKneeWallFeetChange("");
      setSelectedKneeWallInchesChange("");
      setSelectedFasciaInches("");
      setSelectedStructuralBeamOption("");
      setSelectedGutterOption("");
    }
  }, [selectedOperableOption]);

  return (
    <div className="pergola">
      <div className="pergola-grid">
        <div className="style-choices">
          <StyleChoices
            onAttachedOptionChange={handleAttachedOptionChange}
            handleTrimChange={handleTrimOptionChange}
            handleOperableOptionChange={handleOperableOptionChange}
            handlePostStyleOptionChange={handlePostStyleOptionChange}
            handleExteriorOptionChange={handleExteriorOptionChange}
            handleActuatorOptionChange={handleActuatorOptionChange}
            handlePostHeightOption={handlePostHeightOption}
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            handleStructuralBeamChange={handleStructuralBeamChange}
            handleRafterColorOptionChange={handleRafterColorOptionChange}
            handlePergolaGutterChange={handlePergolaGutterChange}
            selectedOperableOption={selectedOperableOption}
            selectedExteriorOption={selectedExteriorOption}
          />
          {selectedOperableOption === "MOTOR CITY" &&
            ((selectedLength === "10" &&
              (selectedWidth === "10" || selectedWidth === "12")) ||
              (selectedLength === "12" &&
                (selectedWidth === "10" || selectedWidth === "12"))) && (
              <SteelPiers
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                handleSteelPiers={handleSteelPiers}
                style={{ width: "49%" }}
              />
            )}
          {selectedOperableOption !== "MOTOR CITY" && (
            <BiHexSelection
              handleBiHexToolOptionChange={handleBiHexOptionChange}
              redBorderFields={fieldsWithRedBorder}
              setFieldsWithRedBorder={setFieldsWithRedBorder}
            />
          )}
        </div>
        <div className="personalinfo-choices">
          <PersonalInfoSelection
            onChange={handlePersonalInfoChange}
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            validateEmail={validateEmail}
            isValidEmail={isValidEmail}
            handleLocationChange={handleLocationChange}
            locations={locations}
            setLocations={setLocations}
          />
        </div>
        <div className="dimensionthings">
          <div className="dimensions-load">
            <div className="dimension-choices">
              {selectedOperableOption !== "MOTOR CITY" && (
                <DimensionsSelection
                  handleDimensionChange={handleDimensionChange}
                  redBorderFields={fieldsWithRedBorder}
                  setFieldsWithRedBorder={setFieldsWithRedBorder}
                />
              )}
              {selectedOperableOption === "MOTOR CITY" && (
                <MotorCityDimensions
                  redBorderFields={fieldsWithRedBorder}
                  setFieldsWithRedBorder={setFieldsWithRedBorder}
                  handleMotorCityPergolaLength={handleMotorCityPergolaLength}
                  handleMotorCityPergolaWidth={handleMotorCityPergolaWidth}
                />
              )}
            </div>
            <div className="load-choices">
              <LoadSelection
                handleRoofLoadOption={handleRoofLoadOption}
                handleWindLoadOption={handleWindLoadOption}
                redBorderFields={fieldsWithRedBorder}
                setFieldsWithRedBorder={setFieldsWithRedBorder}
              />
            </div>
          </div>
          {selectedOperableOption === "PERGOLA OPERABLE" && (
            <div className="density-fanbeam skyview">
              <PergolaGutterSelection
                setFieldsWithRedBorder={setFieldsWithRedBorder}
                redBorderFields={fieldsWithRedBorder}
                handlePergolaGutterChange={handlePergolaGutterChange}
                selectedOperableOption={selectedOperableOption}
              />
            </div>
          )}
        </div>
        <div className="submit-choices">
          <SubmitButton
            selectedTrimOption={selectedTrimOption}
            selectedOperableOption={selectedOperableOption}
            selectedBiHexOption={selectedBiHexOption}
            selectedExteriorOption={selectedExteriorOption}
            selectedPostStyleOption={selectedPostStyleOption}
            selectedActuatorOption={selectedActuatorOption}
            selectedAttachedOption={selectedAttachedOption}
            selectedPermitOption={selectedPermitOption}
            selectedFoundationOption={selectedFoundationOption}
            selectedKneeWallOption={selectedKneeWallOption}
            selectedMountOption={selectedMountOption}
            personalInfo={{
              firstName,
              lastName,
              sender,
              email,
              address,
              city,
              stateProvince,
              zip,
              purchaseOrder,
            }}
            selectedExistingFoundationOption={selectedExistingFoundationOption}
            selectedNewFoundationOption={selectedNewFoundationOption}
            gradeHeightFeet={gradeHeightFeet}
            gradeHeightInches={gradeHeightInches}
            stepDownInches={stepDownInches}
            stepDownFeet={stepDownFeet}
            selectedWindLoadChange={selectedWindLoadChange.toString()}
            selectedRoofLoadChange={selectedRoofLoadChange.toString()}
            widthFeet={widthFeet}
            widthInches={widthInches}
            lengthFeet={lengthFeet}
            lengthInches={lengthInches}
            selectedPostHeight={selectedPostHeight}
            selectedKneeWallFeet={selectedKneeWallFeet}
            selectedKneeWallInches={selectedKneeWallInches}
            selectedFasciaInches={selectedFasciaInches}
            onRedBorderCheck={handleRedBorderCheck}
            selectedBeamOption={selectedBeamOption}
            selectedRafterColor={selectedRafterColor}
            selectedGutterOption={selectedGutterOption}
            selectedWidth={selectedWidth}
            selectedLength={selectedLength}
            initialSteelPiers={initialSteelPiers}
            isValidEmail={isValidEmail}
            selectedLocation={selectedLocation}
            locations={locations}
            setCurrentCADImagesFiles={setCurrentCADImagesFiles}
            currentCADImagesFiles={currentCADImagesFiles}
            drawingImages={drawingImages}
            setDrawingImages={setDrawingImages}
          />
        </div>
        <div className="permit-choices">
          <PermitChoice
            onPermitSelection={handlePermitSelection}
            handlePermitChange={handlePermitChange}
            handleKneeWallChange={handleKneeWallChange}
            handleFoundationChange={handleFoundationChange}
            handleExistingFoundationChange={handleExistingFoundationChange}
            handleNewFoundationChange={handleNewFoundationChange}
            handleGradeHeightOption={handleGradeHeightOption}
            handleKneeWallFeetChange={handleKneeWallFeetChange}
            handleKneeWallInchesChange={handleKneeWallInchesChange}
            redBorderFields={fieldsWithRedBorder}
            setFieldsWithRedBorder={setFieldsWithRedBorder}
            selectedAttachedOption={selectedAttachedOption}
            permitSelected={permitSelected}
            handleMountChange={handleMountChange}
            handleStepDownChange={handleStepDownChange}
            handleFasciaInchesOption={handleFasciaInchesOption}
            selectedMountOption={selectedMountOption}
            selectedOperableOption={selectedOperableOption}
          />
        </div>
      </div>
    </div>
  );
};

export default Pergolas;
