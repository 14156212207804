import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import viewImage from "./view-image-photo-icon.png";
import PropTypes from "prop-types";

const ImageButton = ({ images, messages }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleButtonClick = () => {
    setIsModalVisible(!isModalVisible);
  };

  const modalRoot = document.getElementById("modal-root");

  return (
    <div
      style={{
        gridColumn: "3",
        gridRow: "1",
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <div
        style={{
          width: "fit-content",
          height: "fit-content",
          cursor: "pointer",
          fontSize: "x-small",
        }}
      >
        <div onClick={handleButtonClick}>
          <img src={viewImage} alt="View" style={{ height: "1.9vh" }} />
        </div>
      </div>

      {isModalVisible &&
        ReactDOM.createPortal(
          <div className="modal">
            <div className="modal-content">
              <button
                type="button"
                className="showpdfbuttons"
                style={{
                  position: "absolute",
                  top: "1%",
                  right: "1%",
                  cursor: "pointer",
                  width: "auto",
                  height: "auto",
                }}
                onClick={handleButtonClick}
              >
                X
              </button>
              {images.length <= 3 ? (
                <div className="image-container2">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {images.map((image, index) => (
                      <div
                        key={index}
                        style={{ fontSize: "medium", textAlign: "center" }}
                      >
                        <img src={image} alt={`${index + 1}`} />
                        &nbsp;
                        <p></p>
                        <p>{messages[index]}</p>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="image-container2">
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {images.slice(0, 2).map((image, index) => (
                        <div
                          key={index}
                          style={{ fontSize: "medium", textAlign: "center" }}
                        >
                          <img
                            src={image}
                            alt={`${index + 1}`}
                            style={{
                              width: windowWidth > 1700 ? "21vw" : "23vw",
                              height: windowWidth > 1700 ? "21vw" : "23vw",
                            }}
                          />
                          &nbsp;
                          <p></p>
                          <p>{messages[index]}</p>
                        </div>
                      ))}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {images.slice(2).map((image, index) => (
                        <div
                          key={index + 2}
                          style={{ fontSize: "medium", textAlign: "center" }}
                        >
                          <img
                            src={image}
                            alt={`${index + 3}`}
                            style={{
                              width: windowWidth > 1700 ? "21vw" : "23vw",
                              height: windowWidth > 1700 ? "21vw" : "23vw",
                            }}
                          />
                          &nbsp;
                          <p></p>
                          <p>{messages[index + 2]}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>,
          modalRoot,
        )}
    </div>
  );
};

ImageButton.propTypes = {
  images: PropTypes.array,
  messages: PropTypes.array,
};

export default ImageButton;
