import React, { useState, useEffect, useCallback } from "react";
import MissingFieldsBox from "../UniversalComponents/SubmitMessages/cantsubmitmessage";
import ErrorMessage from "../UniversalComponents/SubmitMessages/ErrorMessage";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import HoldErrorMessage from "../UniversalComponents/SubmitMessages/HoldErrorMessage";
import RepErrorMessage from "../UniversalComponents/SubmitMessages/RepErrorMessage";
import SuccessMessage from "../UniversalComponents/SubmitMessages/SuccessMessage";
import SunroomLockMessage from "../UniversalComponents/SubmitMessages/SunroomLock";
// import DrawingOverlaySelection from "../DrawingOverlay/DrawingOverlaySelection";

const SunroomSubmitButton = ({
  selectedPermitOption,
  selectedFoundationOption,
  selectedKneeWallOption,
  selectedMountOption,
  personalInfo,
  selectedExistingFoundationOption,
  selectedNewFoundationOption,
  gradeHeightFeet,
  gradeHeightInches,
  stepDownInches,
  stepDownFeet,
  selectedWindLoadChange,
  selectedRoofLoadChange,
  widthFeet,
  widthInches,
  lengthFeet,
  lengthInches,
  selectedKneeWallFeet,
  selectedKneeWallInches,
  selectedFasciaInches,
  onRedBorderCheck,
  selectedShingle,
  selectedFanBeam,
  selectedRoofDensity,
  heightAtHouse,
  frontHeight,
  selectedSkyviewChoice,
  selectedValueSeries,
  selectedLEDLightChoice,
  deckFlashingOption,
  selectedElectricalOption,
  selectedVertChannelOption,
  selectedWindowWallStyle,
  selectedRoomStyle,
  selectedBotTransom,
  selectedBackWallPanels,
  frameOption,
  fasciaTrimOption,
  exteriorKpOption,
  interiorKpOption,
  selectedBotTransomOption,
  selectedTopTransomOption,
  selectedCleatsOrBond,
  selectedRoofPanelColorOption,
  selectedACLeftOption,
  selectedACCenterOption,
  selectedACRightOption,
  selectedCodes,
  selectedSwingDoorOrientation,
  selectedPatioDoorOrientation,
  selectedDoorHardware,
  doorWidth,
  doorOption,
  wallLengthsFeet,
  wallLengthsInches,
  SSHinges,
  selectedSkinType,
  selectedPavilionLayoutOption,
  selectedRailSideOption,
  selectedRailAmountOption,
  selectedSunRoomType,
  initialSteelPiers,
  deckWidthInchesOption,
  deckLengthInchesOption,
  deckWidthFeetOption,
  deckLengthFeetOption,
  isValidEmail,
  selectedLocation,
  locations,
  selectedBreezeWay,
  selectedTopCustomReady,
  wallERLLengthsFeet,
  wallERLLengthsInches,
  wallERRLengthsFeet,
  wallERRLengthsInches,
  wallERFLengthsFeet,
  wallERFLengthsInches,
  doorERFOption,
  doorERROption,
  doorERLOption,
  doorERFWidth,
  doorERRWidth,
  doorERLWidth,
  selectedERLPatioDoorOrientation,
  selectedERLSwingDoorOrientation,
  selectedERRPatioDoorOrientation,
  selectedERRSwingDoorOrientation,
  selectedERFPatioDoorOrientation,
  selectedERFSwingDoorOrientation,
  selectedERLCodes,
  selectedERRCodes,
  selectedERFCodes,
  selectedAngled,
  selectedAngleOption,
  wallStraightLengthsInches,
  wallStraightLengthsFeet,
  doorStraightOption,
  doorStraightWidth,
  selectedStraightSwingDoorOrientation,
  selectedStraightPatioDoorOrientation,
  selectedStraightCodes,
  leftAngleFeet,
  leftAngleInches,
  rightAngleFeet,
  rightAngleInches,
  sashOption,
  isScreenRoom,
  selectedERLLocations,
  selectedERRLocations,
  selectedERFLocations,
  selectedLocations,
  chairRailWidthFeet,
  chairRailWidthInches,
  bottomKPWidthFeet,
  bottomKPWidthInches,
  selectedTEMOCornersOption,
  screenDoorWidth,
  vinylOption,
  glassRows,
  bays,
  // setCurrentCADImagesFiles,
  // currentCADImagesFiles,
  // drawingImages,
  // setDrawingImages,
}) => {
  const [formattedMissingFields, setFormattedMissingFields] = useState([""]);
  const [missingFields, setMissingFields] = useState([""]);
  const [showMissingFields, setShowMissingFields] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [endMessage, setEndMessage] = useState("");
  const [showEndMessage, setShowEndMessage] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState([""]);
  const [sentCorrectly, setSentCorrectly] = useState(false);
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  // const [isDrawingOverlayOpen, setIsDrawingOverlayOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  const handleAdditionalInfoChange = (index, value) => {
    const newAdditionalInfo = [...additionalInfo];
    newAdditionalInfo[index] =
      value.length > 48 ? value.substring(0, 48) : value;
    setAdditionalInfo(newAdditionalInfo);
  };

  const openModal = (e) => {
    e.preventDefault();
    setShowMissingFields(true);
    onRedBorderCheck(missingFields);
    if (formattedMissingFields.length === 0) {
      setShowModal(true);
    }
  };

  const closeModal = (e) => {
    setShowModal(false);
  };

  const checkMissingFields = useCallback(() => {
    const newFormattedMissingFields = [];
    const missingFields = [];

    if (selectedSunRoomType === "Solarium") {
      if (!bays) {
        newFormattedMissingFields.push("Bays");
        missingFields.push("Bays");
      }
      if (!glassRows) {
        newFormattedMissingFields.push("Glass Rows");
        missingFields.push("Glass Rows");
      }
    }

    if (selectedRoomStyle === "Existing Roof") {
      if (!selectedTEMOCornersOption) {
        newFormattedMissingFields.push("TEMO CORNERS YES or NO");
        missingFields.push("TEMO CORNERS YES or NO");
      }
    }

    if (selectedSunRoomType === "Vinyl View") {
      if (!vinylOption) {
        newFormattedMissingFields.push("Vinyl Color");
        missingFields.push("Vinyl Color");
      }
    }

    if (selectedSunRoomType === "Linear Screen") {
      if (!chairRailWidthFeet) {
        newFormattedMissingFields.push("Chair Rail Feet");
        missingFields.push("chair rail feet");
      }
      if (!chairRailWidthInches) {
        newFormattedMissingFields.push("Chair Rail Inches");
        missingFields.push("chair rail inches");
      }
      if (!bottomKPWidthFeet) {
        newFormattedMissingFields.push("Bottom KP Feet");
        missingFields.push("bottom kp feet");
      }
      if (!bottomKPWidthInches) {
        newFormattedMissingFields.push("Bottom KP Inches");
        missingFields.push("bottom kp inches");
      }
    }

    if (
      !isScreenRoom ||
      (isScreenRoom && selectedSunRoomType === "Value View")
    ) {
      if (!sashOption) {
        newFormattedMissingFields.push("Sash Option");
        missingFields.push("Sash Option");
      }
    }

    if (!selectedTopCustomReady && selectedSunRoomType !== "Linear Screen") {
      newFormattedMissingFields.push("TCR");
      missingFields.push("TCR");
    }

    if (!selectedSunRoomType) {
      newFormattedMissingFields.push("Sunroom Type");
      missingFields.push("Sunroom Type");
    }
    if (
      selectedSunRoomType !== "Linear Screen" &&
      selectedSunRoomType !== "Solarium"
    ) {
      if (!selectedAngled) {
        newFormattedMissingFields.push("Angled Option");
        missingFields.push("Angled Option");
      }
    }
    if (selectedAngled === "Yes") {
      if (selectedRoomStyle !== "Existing Roof") {
        if (
          !selectedAngleOption.toString() ||
          selectedAngleOption.toString() === "0"
        ) {
          newFormattedMissingFields.push("Angled Layout");
          missingFields.push("Angled Layout");
        }
        for (let i = 0; i < doorStraightWidth.length; i++) {
          if (
            doorStraightWidth[i] === "3' Door" ||
            doorStraightWidth[i] === "3.5' Door"
          ) {
            if (!selectedDoorHardware) {
              newFormattedMissingFields.push("Door Hardware");
              missingFields.push("Door Hardware");
            }
            if (!SSHinges) {
              newFormattedMissingFields.push("SS Hinges");
              missingFields.push("SS Hinges");
            }
          }
        }
        const wallLengthLabels = [
          {
            feet: "Left Straight Length Feet",
            inches: "Left Straight Length Inches",
            doorOption: "Left Straight Door Option",
            doorWidth: "Left Straight Door Width",
            selectedCodes: "Left Straight Wall Code",
            patioDoor: "Left Straight Patio Door Orientation",
            swingDoor: "Left Straight Swing Door Orientation",
          },
          {
            feet: "Front Length Feet",
            inches: "Front Length Inches",
            doorOption: "Front Door Option",
            doorWidth: "Front Door Width",
            selectedCodes: "Front Wall Code",
            patioDoor: "Front Patio Door Orientation",
            swingDoor: "Front Swing Door Orientation",
          },
          {
            feet: "Right Straight Length Feet",
            inches: "Right Straight Length Inches",
            doorOption: "Right Straight Door Option",
            doorWidth: "Right Straight Door Width",
            selectedCodes: "Right Straight Wall Code",
            patioDoor: "Right Straight Patio Door Orientation",
            swingDoor: "Right Straight Swing Door Orientation",
          },
          {
            feet: "Left Angle Length Feet",
            inches: "Left Angle Length Inches",
            doorOption: "Left Angle Door Option",
            doorWidth: "Left Angle Door Width",
            selectedCodes: "Left Angle Wall Code",
            patioDoor: "Left Angle Patio Door Orientation",
            swingDoor: "Left Angle Swing Door Orientation",
          },
          {
            feet: "Right Angle Length Feet",
            inches: "Right Angle Length Inches",
            doorOption: "Right Angle Door Option",
            doorWidth: "Right Angle Door Width",
            selectedCodes: "Right Angle Wall Code",
            patioDoor: "Right Angle Patio Door Orientation",
            swingDoor: "Right Angle Swing Door Orientation",
          },
        ];

        const angleOptionsForIndex = [
          ["1", "2", "3", "4", "7"],
          ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
          ["1", "2", "7", "8", "9"],
          ["1", "2", "3", "4", "4", "6", "8"],
          ["1", "3", "6", "7", "8", "9", "10"],
        ];

        for (let i = 0; i < wallLengthLabels.length; i++) {
          if (
            angleOptionsForIndex[i].includes(selectedAngleOption.toString())
          ) {
            if (
              !wallStraightLengthsFeet[i] ||
              wallStraightLengthsFeet[i] === ""
            ) {
              newFormattedMissingFields.push(wallLengthLabels[i].feet);
              missingFields.push(wallLengthLabels[i].feet);
            }
            if (
              !wallStraightLengthsInches[i] ||
              wallStraightLengthsInches[i] === ""
            ) {
              newFormattedMissingFields.push(wallLengthLabels[i].inches);
              missingFields.push(wallLengthLabels[i].inches);
            }
            if (
              (wallStraightLengthsFeet[i] > 4 &&
                selectedSunRoomType !== "Premier View") ||
              (wallStraightLengthsFeet[i] >= 5 &&
                selectedSunRoomType === "Premier View")
            ) {
              if (!doorStraightOption[i]) {
                newFormattedMissingFields.push(wallLengthLabels[i].doorOption);
                missingFields.push(wallLengthLabels[i].doorOption);
              } else if (doorStraightOption[i] === "Yes") {
                if (!doorStraightWidth[i]) {
                  newFormattedMissingFields.push(wallLengthLabels[i].doorWidth);
                  missingFields.push(wallLengthLabels[i].doorWidth);
                } else if (
                  doorStraightWidth[i] === "3' Door" ||
                  doorStraightWidth[i] === "3.5' Door"
                ) {
                  if (!selectedStraightCodes[i]) {
                    newFormattedMissingFields.push(
                      wallLengthLabels[i].selectedCodes,
                    );
                    missingFields.push(wallLengthLabels[i].selectedCodes);
                  }
                  if (!selectedStraightSwingDoorOrientation[i]) {
                    newFormattedMissingFields.push(
                      wallLengthLabels[i].swingDoor,
                    );
                    missingFields.push(wallLengthLabels[i].swingDoor);
                  }
                } else if (
                  doorStraightWidth[i] !== "3' Door" &&
                  doorStraightWidth[i] !== "3.5' Door" &&
                  doorStraightWidth[i] !== ""
                ) {
                  if (!selectedStraightCodes[i]) {
                    newFormattedMissingFields.push(
                      wallLengthLabels[i].selectedCodes,
                    );
                    missingFields.push(wallLengthLabels[i].selectedCodes);
                  }
                  if (!selectedStraightPatioDoorOrientation[i]) {
                    newFormattedMissingFields.push(
                      wallLengthLabels[i].patioDoor,
                    );
                    missingFields.push(wallLengthLabels[i].patioDoor);
                  }
                }
              }
            } else {
              if (!selectedStraightCodes[i]) {
                newFormattedMissingFields.push(
                  wallLengthLabels[i].selectedCodes,
                );
                missingFields.push(wallLengthLabels[i].selectedCodes);
              }
            }
          }
        }
      }
    } else {
      if (!selectedPavilionLayoutOption) {
        newFormattedMissingFields.push("Structure Layout");
        missingFields.push("StructureLayoutImages");
      }
      if (Number(selectedPavilionLayoutOption) === 2) {
        if (!selectedBreezeWay) {
          newFormattedMissingFields.push("Breeze Way");
          missingFields.push("Breeze Way");
        }
        if (selectedBreezeWay === "No") {
          if (!selectedRailSideOption) {
            newFormattedMissingFields.push("Rail Side");
            missingFields.push("rail side");
            missingFields.push("StructureLayoutImages");
          }
        }
        if (selectedRoomStyle !== "Existing Roof") {
          if (!selectedRailAmountOption.feet) {
            newFormattedMissingFields.push("Rail Amount Feet");
            missingFields.push("rail amount Feet");
            missingFields.push("StructureLayoutImages");
          }
          if (!selectedRailAmountOption.inches) {
            newFormattedMissingFields.push("Rail Amount Inches");
            missingFields.push("rail amount Inches");
            missingFields.push("StructureLayoutImages");
          }
        }
      }
      if (
        Number(selectedPavilionLayoutOption) === 3 &&
        selectedRoomStyle !== "Existing Roof"
      ) {
        if (!selectedRailAmountOption.feet) {
          newFormattedMissingFields.push("Rail Amount Feet");
          missingFields.push("rail amount Feet");
          missingFields.push("StructureLayoutImages");
        }
        if (!selectedRailAmountOption.inches) {
          newFormattedMissingFields.push("Rail Amount Inches");
          missingFields.push("rail amount Inches");
          missingFields.push("StructureLayoutImages");
        }
      }
    }

    if (!selectedSkinType) {
      newFormattedMissingFields.push("Skin Type");
      missingFields.push("Skin Type");
    }

    if (selectedRoomStyle === "Existing Roof") {
      let addedDoorHardware = false;
      let addedSSHinges = false;

      for (let i = 0; i < doorERFWidth.length; i++) {
        if (doorERFWidth[i] === "3' Door" || doorERFWidth[i] === "3.5' Door") {
          if (!selectedDoorHardware && !addedDoorHardware) {
            newFormattedMissingFields.push("Door Hardware");
            missingFields.push("Door Hardware");

            addedDoorHardware = true;
          }
          if (!SSHinges && !addedSSHinges) {
            newFormattedMissingFields.push("SS Hinges");
            missingFields.push("SS Hinges");

            addedSSHinges = true;
          }
        }
      }
      for (let i = 0; i < doorERRWidth.length; i++) {
        if (doorERRWidth[i] === "3' Door" || doorERRWidth[i] === "3.5' Door") {
          if (!selectedDoorHardware && !addedDoorHardware) {
            newFormattedMissingFields.push("Door Hardware");
            missingFields.push("Door Hardware");

            addedDoorHardware = true;
          }
          if (!SSHinges && !addedSSHinges) {
            newFormattedMissingFields.push("SS Hinges");
            missingFields.push("SS Hinges");

            addedSSHinges = true;
          }
        }
      }
      for (let i = 0; i < doorERLWidth.length; i++) {
        if (doorERLWidth[i] === "3' Door" || doorERLWidth[i] === "3.5' Door") {
          if (!selectedDoorHardware && !addedDoorHardware) {
            newFormattedMissingFields.push("Door Hardware");
            missingFields.push("Door Hardware");

            addedDoorHardware = true;
          }
          if (!SSHinges && !addedSSHinges) {
            newFormattedMissingFields.push("SS Hinges");
            missingFields.push("SS Hinges");
            addedSSHinges = true;
          }
        }
      }
      if (
        Number(selectedPavilionLayoutOption) === 1 ||
        (Number(selectedPavilionLayoutOption) === 2 &&
          selectedBreezeWay === "Yes") ||
        (Number(selectedPavilionLayoutOption) === 2 &&
          selectedBreezeWay === "No" &&
          selectedRailSideOption === "LEFT")
      ) {
        for (let i = 0; i < wallERLLengthsFeet.length; i++) {
          if (!wallERLLengthsFeet[i] || wallERLLengthsFeet[i] === "") {
            newFormattedMissingFields.push(`Left Wall Length Feet ${i + 1}`);
            missingFields.push(`Left Wall Length Feet ${i + 1}`);
          }
          if (!wallERLLengthsInches[i]) {
            newFormattedMissingFields.push(`Left Wall Length Inches ${i + 1}`);
            missingFields.push(`Left Wall Length Inches ${i + 1}`);
          }
        }
        for (let i = 0; i < wallERLLengthsFeet.length; i++) {
          if (
            (wallERLLengthsFeet[i] >= 4 &&
              selectedSunRoomType !== "Premier View") ||
            (wallERLLengthsFeet[i] >= 5 &&
              selectedSunRoomType === "Premier View")
          ) {
            if (!doorERLOption[i]) {
              newFormattedMissingFields.push(`Left Door Option ${i + 1}`);
              missingFields.push(`Left Door Option ${i + 1}`);
            } else if (doorERLOption[i] === "Yes") {
              if (!doorERLWidth[i]) {
                newFormattedMissingFields.push(`Left Door Width ${i + 1}`);
                missingFields.push(`Left Door Width ${i + 1}`);
              } else if (
                doorERLWidth[i] === "3' Door" ||
                doorERLWidth[i] === "3.5' Door"
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERLCodes[i]) {
                    newFormattedMissingFields.push(`Left Wall Code ${i + 1}`);
                    missingFields.push(`Left Wall Code ${i + 1}`);
                  }
                } else {
                  if (!selectedERLLocations[i]) {
                    newFormattedMissingFields.push(
                      `Left Door Location ${i + 1}`,
                    );
                    missingFields.push(`Left Door Location ${i + 1}`);
                  }
                }
                if (!selectedERLSwingDoorOrientation[i]) {
                  newFormattedMissingFields.push(
                    `Left Swing Door Orientation ${i + 1}`,
                  );
                  missingFields.push(`Left Swing Door Orientation ${i + 1}`);
                }
              } else if (
                doorERLWidth[i] !== "3' Door" &&
                doorERLWidth[i] !== "3.5' Door" &&
                doorERLWidth[i] !== ""
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERLCodes[i]) {
                    newFormattedMissingFields.push(`Left Wall Code ${i + 1}`);
                    missingFields.push(`Left Wall Code ${i + 1}`);
                  }
                }
                if (!selectedERLPatioDoorOrientation[i]) {
                  newFormattedMissingFields.push(
                    `Left Patio Door Orientation ${i + 1}`,
                  );
                  missingFields.push(`Left Patio Door Orientation ${i + 1}`);
                }
              }
            } else {
              if (selectedSunRoomType !== "Linear Screen") {
                if (!selectedERLCodes[i]) {
                  newFormattedMissingFields.push(`Left Wall Code ${i + 1}`);
                  missingFields.push(`Left Wall Code ${i + 1}`);
                }
              }
            }
          } else {
            if (selectedSunRoomType !== "Linear Screen") {
              if (!selectedERLCodes[i]) {
                newFormattedMissingFields.push(`Left Wall Code ${i + 1}`);
                missingFields.push(`Left Wall Code ${i + 1}`);
              }
            }
          }
        }
      }
      if (
        Number(selectedPavilionLayoutOption) === 1 ||
        (Number(selectedPavilionLayoutOption) === 2 &&
          selectedBreezeWay === "Yes") ||
        (Number(selectedPavilionLayoutOption) === 2 &&
          selectedBreezeWay === "No" &&
          selectedRailSideOption === "RIGHT")
      ) {
        for (let i = 0; i < wallERRLengthsFeet.length; i++) {
          if (!wallERRLengthsFeet[i] || wallERRLengthsFeet[i] === "") {
            newFormattedMissingFields.push(`Right Wall Length Feet ${i + 1}`);
            missingFields.push(`Right Wall Length Feet ${i + 1}`);
          }
          if (!wallERRLengthsInches[i]) {
            newFormattedMissingFields.push(`Right Wall Length Inches ${i + 1}`);
            missingFields.push(`Right Wall Length Inches ${i + 1}`);
          }
        }
        for (let i = 0; i < wallERRLengthsFeet.length; i++) {
          if (
            (wallERRLengthsFeet[i] >= 4 &&
              selectedSunRoomType !== "Premier View") ||
            (wallERRLengthsFeet[i] >= 5 &&
              selectedSunRoomType === "Premier View")
          ) {
            if (!doorERROption[i]) {
              newFormattedMissingFields.push(`Right Door Option ${i + 1}`);
              missingFields.push(`Right Door Option ${i + 1}`);
            } else if (doorERROption[i] === "Yes") {
              if (!doorERRWidth[i]) {
                newFormattedMissingFields.push(`Right Door Width ${i + 1}`);
                missingFields.push(`Right Door Width ${i + 1}`);
              } else if (
                doorERRWidth[i] === "3' Door" ||
                doorERRWidth[i] === "3.5' Door"
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERRCodes[i]) {
                    newFormattedMissingFields.push(`Right Wall Code ${i + 1}`);
                    missingFields.push(`Right Wall Code ${i + 1}`);
                  }
                } else {
                  if (!selectedERRLocations[i]) {
                    newFormattedMissingFields.push(
                      `Right Door Location ${i + 1}`,
                    );
                    missingFields.push(`Right Door Location ${i + 1}`);
                  }
                }
                if (!selectedERRSwingDoorOrientation[i]) {
                  newFormattedMissingFields.push(
                    `Right Swing Door Orientation ${i + 1}`,
                  );
                  missingFields.push(`Right Swing Door Orientation ${i + 1}`);
                }
              } else if (
                doorERRWidth[i] !== "3' Door" &&
                doorERRWidth[i] !== "3.5' Door" &&
                doorERRWidth[i] !== ""
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERRCodes[i]) {
                    newFormattedMissingFields.push(`Right Wall Code ${i + 1}`);
                    missingFields.push(`Right Wall Code ${i + 1}`);
                  }
                }
                if (!selectedERRPatioDoorOrientation[i]) {
                  newFormattedMissingFields.push(
                    `Right Patio Door Orientation ${i + 1}`,
                  );
                  missingFields.push(`Right Patio Door Orientation ${i + 1}`);
                }
              }
            } else {
              if (selectedSunRoomType !== "Linear Screen") {
                if (!selectedERRCodes[i]) {
                  newFormattedMissingFields.push(`Right Wall Code ${i + 1}`);
                  missingFields.push(`Right Wall Code ${i + 1}`);
                }
              }
            }
          } else {
            if (selectedSunRoomType !== "Linear Screen") {
              if (!selectedERRCodes[i]) {
                newFormattedMissingFields.push(`Right Wall Code ${i + 1}`);
                missingFields.push(`Right Wall Code ${i + 1}`);
              }
            }
          }
        }
      }
      if (selectedBreezeWay !== "Yes") {
        for (let i = 0; i < wallERFLengthsFeet.length; i++) {
          if (!wallERFLengthsFeet[i] || wallERFLengthsFeet[i] === "") {
            newFormattedMissingFields.push(`Front Wall Length Feet ${i + 1}`);
            missingFields.push(`Front Wall Length Feet ${i + 1}`);
          }
          if (!wallERFLengthsInches[i]) {
            newFormattedMissingFields.push(`Front Wall Length Inches ${i + 1}`);
            missingFields.push(`Front Wall Length Inches ${i + 1}`);
          }
        }
        for (let i = 0; i < wallERFLengthsFeet.length; i++) {
          if (
            (wallERFLengthsFeet[i] >= 4 &&
              selectedSunRoomType !== "Premier View") ||
            (wallERFLengthsFeet[i] >= 6 &&
              selectedSunRoomType === "Premier View")
          ) {
            if (!doorERFOption[i]) {
              newFormattedMissingFields.push(`Front Door Option ${i + 1}`);
              missingFields.push(`Front Door Option ${i + 1}`);
            } else if (doorERFOption[i] === "Yes") {
              if (!doorERFWidth[i]) {
                newFormattedMissingFields.push(`Front Door Width ${i + 1}`);
                missingFields.push(`Front Door Width ${i + 1}`);
              } else if (
                doorERFWidth[i] === "3' Door" ||
                doorERFWidth[i] === "3.5' Door"
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERFCodes[i]) {
                    newFormattedMissingFields.push(`Front Wall Code ${i + 1}`);
                    missingFields.push(`Front Wall Code ${i + 1}`);
                  }
                } else {
                  if (!selectedERFLocations[i]) {
                    newFormattedMissingFields.push(
                      `Front Door Location ${i + 1}`,
                    );
                    missingFields.push(`Front Door Location ${i + 1}`);
                  }
                }
                if (!selectedERFSwingDoorOrientation[i]) {
                  newFormattedMissingFields.push(
                    `Front Swing Door Orientation ${i + 1}`,
                  );
                  missingFields.push(`Front Swing Door Orientation ${i + 1}`);
                }
              } else if (
                doorERFWidth[i] !== "3' Door" &&
                doorERFWidth[i] !== "3.5' Door" &&
                doorERFWidth[i] !== ""
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERFCodes[i]) {
                    newFormattedMissingFields.push(`Front Wall Code ${i + 1}`);
                    missingFields.push(`Front Wall Code ${i + 1}`);
                  }
                }
                if (!selectedERFPatioDoorOrientation[i]) {
                  newFormattedMissingFields.push(
                    `Front Patio Door Orientation ${i + 1}`,
                  );
                  missingFields.push(`Front Patio Door Orientation ${i + 1}`);
                }
              }
            } else {
              if (selectedSunRoomType !== "Linear Screen") {
                if (!selectedERFCodes[i]) {
                  newFormattedMissingFields.push(`Front Wall Code ${i + 1}`);
                  missingFields.push(`Front Wall Code ${i + 1}`);
                }
              }
            }
          } else {
            if (selectedSunRoomType !== "Linear Screen") {
              if (!selectedERFCodes[i]) {
                newFormattedMissingFields.push(`Front Wall Code ${i + 1}`);
                missingFields.push(`Front Wall Code ${i + 1}`);
              }
            }
          }
        }
      }
    }

    if (!selectedACLeftOption) {
      newFormattedMissingFields.push("Left Projection AC");
      missingFields.push("LeftProj");
    }
    if (!selectedACCenterOption) {
      newFormattedMissingFields.push("Front Wall AC");
      missingFields.push("FrontWall");
    }
    if (!selectedACRightOption) {
      newFormattedMissingFields.push("Right Projection AC");
      missingFields.push("RightProj");
    }
    if (selectedRoomStyle !== "Existing Roof") {
      if (selectedAngled !== "Yes") {
        for (let i = 0; i < wallLengthsFeet.length; i++) {
          if (
            selectedSunRoomType === "Solarium" &&
            (i === 1 || (selectedPavilionLayoutOption === "3" && i === 0))
          ) {
            continue;
          }
          if (!wallLengthsFeet[i] || wallLengthsFeet[i] === "") {
            newFormattedMissingFields.push(`Wall Length Feet ${i + 1}`);
            missingFields.push(`Wall Length Feet ${i + 1}`);
          }
          if (!wallLengthsInches[i]) {
            newFormattedMissingFields.push(`Wall Length Inches ${i + 1}`);
            missingFields.push(`Wall Length Inches ${i + 1}`);
          }
        }
        let screenDoorWidthAdded = false;
        for (let i = 0; i < wallLengthsFeet.length; i++) {
          if (
            selectedSunRoomType === "Solarium" &&
            (i === 1 || (selectedPavilionLayoutOption === "3" && i === 0))
          ) {
            continue;
          }
          if (!doorOption[i]) {
            newFormattedMissingFields.push(`Door Option ${i + 1}`);
            missingFields.push(`Door Option ${i + 1}`);
          } else if (doorOption[i] === "Yes") {
            if (selectedSunRoomType === "Linear Screen") {
              if (!screenDoorWidth && !screenDoorWidthAdded) {
                newFormattedMissingFields.push("Screen Door Width");
                missingFields.push("Screen Door Width");
                screenDoorWidthAdded = true;
              }
            }
            if (!doorWidth[i]) {
              newFormattedMissingFields.push(`Door Width ${i + 1}`);
              missingFields.push(`Door Width ${i + 1}`);
            } else if (
              doorWidth[i] === "3' Door" ||
              doorWidth[i] === "3.5' Door"
            ) {
              if (selectedSunRoomType !== "Linear Screen") {
                if (!selectedCodes[i]) {
                  newFormattedMissingFields.push(`Wall Code ${i + 1}`);
                  missingFields.push(`Wall Code ${i + 1}`);
                }
              } else {
                if (!selectedLocations[i]) {
                  newFormattedMissingFields.push(`Door Location ${i + 1}`);
                  missingFields.push(`Door Location ${i + 1}`);
                }
              }
              if (!selectedSwingDoorOrientation[i]) {
                newFormattedMissingFields.push(
                  `Swing Door Orientation ${i + 1}`,
                );
                missingFields.push(`Swing Door Orientation ${i + 1}`);
              }
              if (!selectedDoorHardware) {
                newFormattedMissingFields.push("Door Hardware");
                missingFields.push("Door Hardware");
              }
              if (!SSHinges) {
                newFormattedMissingFields.push("SS Hinges");
                missingFields.push("SS Hinges");
              }
            } else if (
              doorWidth[i] !== "3' Door" &&
              doorWidth[i] !== "3.5' Door" &&
              doorWidth[i] !== ""
            ) {
              if (selectedSunRoomType !== "Linear Screen") {
                if (!selectedCodes[i]) {
                  newFormattedMissingFields.push(`Wall Code ${i + 1}`);
                  missingFields.push(`Wall Code ${i + 1}`);
                }
              }
              if (!selectedPatioDoorOrientation[i]) {
                newFormattedMissingFields.push(
                  `Patio Door Orientation ${i + 1}`,
                );
                missingFields.push(`Patio Door Orientation ${i + 1}`);
              }
            }
          } else {
            if (selectedSunRoomType !== "Linear Screen") {
              if (!selectedCodes[i]) {
                newFormattedMissingFields.push(`Wall Code ${i + 1}`);
                missingFields.push(`Wall Code ${i + 1}`);
              }
            }
          }
        }
      }

      if (
        selectedRoofDensity !== '4 1/4" 0.032 1 pound' &&
        selectedRoofDensity !== '4 1/4" 0.032 2 pound' &&
        selectedSkyviewChoice !== "Skyview YES"
      ) {
        if (!selectedRoofPanelColorOption) {
          newFormattedMissingFields.push("Roof Panel Color");
          missingFields.push("RoofPanelColors");
        }
      }

      if (selectedSunRoomType !== "Solarium") {
        if (!selectedValueSeries) {
          newFormattedMissingFields.push("Value Series");
          missingFields.push("Value Series");
        }
      }
      if (
        selectedRoomStyle !== "CATHEDRAL" &&
        selectedRoomStyle !== "Existing Roof" &&
        selectedSunRoomType !== "Solarium" &&
        selectedAngled !== "Yes"
      ) {
        if (!selectedSkyviewChoice) {
          newFormattedMissingFields.push("Skyview");
          missingFields.push("Skyview YES or NO");
        }
      }
      if (selectedSkyviewChoice === "Skyview YES") {
        if (!selectedLEDLightChoice) {
          newFormattedMissingFields.push("L.E.D. Lights");
          missingFields.push("LED YES or NO");
        }
      }
      if (
        selectedSkyviewChoice !== "Skyview YES" &&
        selectedSunRoomType !== "Solarium"
      ) {
        if (!selectedShingle) {
          newFormattedMissingFields.push("Shingle Roof");
          missingFields.push("Shingle Roof");
        }
        if (selectedShingle !== "YES") {
          if (!selectedCleatsOrBond) {
            newFormattedMissingFields.push("Cleats or Bond Tape");
            missingFields.push("Cleats or Bond Tape");
          }
        }
      }
      if (!lengthFeet || lengthFeet < 4) {
        newFormattedMissingFields.push("Length Feet");
        missingFields.push("length feet");
      }
      if (!lengthInches) {
        newFormattedMissingFields.push("Length Inches");
        missingFields.push("length inches");
      }
      if (selectedSunRoomType !== "Solarium") {
        if (!widthFeet || widthFeet < 4) {
          newFormattedMissingFields.push("Width Feet");
          missingFields.push("width feet");
        }
        if (!widthInches) {
          newFormattedMissingFields.push("Width Inches");
          missingFields.push("width inches");
        }
      }
      if (!selectedRoofLoadChange) {
        newFormattedMissingFields.push("Roof Load");
        missingFields.push("RoofLoad");
      }
      if (!selectedFanBeam) {
        newFormattedMissingFields.push("Fan Beam Option");
        missingFields.push("fan beam");
      }
      if (!selectedRoofDensity) {
        newFormattedMissingFields.push("Roof Density");
        missingFields.push("roof density");
      }
    }

    if (!selectedWindLoadChange) {
      newFormattedMissingFields.push("Wind Load");
      missingFields.push("WindLoad");
    }

    if (!heightAtHouse.heightfeet) {
      newFormattedMissingFields.push("Height at House Feet");
      missingFields.push("Height at House Heightfeet");
    }

    if (!heightAtHouse.heightinches) {
      newFormattedMissingFields.push("Height at House Inches");
      missingFields.push("Height at House Heightinches");
    }

    if (!frontHeight.frontfeet) {
      newFormattedMissingFields.push("Front Height Feet");
      missingFields.push("Front Height Frontfeet");
    }

    if (!frontHeight.frontinches) {
      newFormattedMissingFields.push("Front Height Inches");
      missingFields.push("Front Height Frontinches");
    }
    for (const key in personalInfo) {
      if (key === "firstName" && personalInfo[key] === "") {
        continue;
      }
      if (key === "purchaseOrder" && personalInfo[key] === "") {
        continue;
      }
      if (!personalInfo[key]) {
        missingFields.push(key);
        if (key === "lastName") {
          newFormattedMissingFields.push("Last Name");
        }
        if (key === "sender") {
          newFormattedMissingFields.push("Sender");
        }
        if (key === "email") {
          newFormattedMissingFields.push("Email");
        }
        if (key === "address") {
          newFormattedMissingFields.push("Address");
        }
        if (key === "city") {
          newFormattedMissingFields.push("City");
        }
        if (key === "stateProvince") {
          newFormattedMissingFields.push("State/Province");
        }
        if (key === "zip") {
          newFormattedMissingFields.push("Zip Code");
        }
      }
    }
    if (personalInfo["email"] && !isValidEmail) {
      newFormattedMissingFields.push("Invalid Email");
      missingFields.push("email");
    }
    if (locations && Object.keys(locations).length > 0) {
      if (!selectedLocation) {
        newFormattedMissingFields.push("Location");
        missingFields.push("location");
      }
    }
    if (!selectedPermitOption) {
      newFormattedMissingFields.push("Drawing Package");
      missingFields.push("permit package option");
    }
    if (selectedPermitOption === "YES") {
      if (!initialSteelPiers) {
        newFormattedMissingFields.push("Steel Piers");
        missingFields.push("Steel Piers");
      }
      if (!selectedFoundationOption) {
        newFormattedMissingFields.push("Foundation Option");
        missingFields.push("foundation option");
      }
      if (selectedFoundationOption === "newconcrete") {
        if (!selectedNewFoundationOption) {
          newFormattedMissingFields.push("New Concrete Option");
          missingFields.push("Pick a new concrete option");
        }
      }
      if (selectedFoundationOption === "existingconcrete") {
        if (!selectedExistingFoundationOption) {
          newFormattedMissingFields.push("Existing Concrete Option");
          missingFields.push("Pick an existing slab option");
        }
      }

      if (
        selectedFoundationOption === "existingwood" ||
        selectedFoundationOption === "nontemowood" ||
        selectedFoundationOption === "temodeck"
      ) {
        if (!gradeHeightInches) {
          newFormattedMissingFields.push("Grade Height Inches");
          missingFields.push("Please input inches grade height");
        }
        if (!gradeHeightFeet) {
          newFormattedMissingFields.push("Grade Height Feet");
          missingFields.push("Please input feet grade height");
        }
      }
      if (selectedFoundationOption === "temodeck") {
        if (!deckFlashingOption) {
          newFormattedMissingFields.push("Deck Flashing Option");
          missingFields.push("deck flashing option");
        }
        if (!deckWidthFeetOption) {
          newFormattedMissingFields.push("Deck Width Feet");
          missingFields.push("deck width feet");
        }
        if (!deckWidthInchesOption) {
          newFormattedMissingFields.push("Deck Width Inches");
          missingFields.push("deck width inches");
        }
        if (!deckLengthFeetOption) {
          newFormattedMissingFields.push("Deck Length Feet");
          missingFields.push("deck length feet");
        }
        if (!deckLengthInchesOption) {
          newFormattedMissingFields.push("Deck Length Inches");
          missingFields.push("deck length inches");
        }
      }
      if (!selectedKneeWallOption) {
        newFormattedMissingFields.push("Knee Wall Option");
        missingFields.push("knee wall option");
      } else if (selectedKneeWallOption === "yes") {
        if (!selectedKneeWallFeet) {
          newFormattedMissingFields.push("Knee Wall Feet");
          missingFields.push("Select Knee Wall Feet");
        }
        if (!selectedKneeWallInches) {
          newFormattedMissingFields.push("Knee Wall Inches");
          missingFields.push("Select Knee Wall Inches");
        }
      }
      if (selectedRoomStyle !== "Existing Roof") {
        if (!selectedMountOption) {
          newFormattedMissingFields.push("Mount Option");
          missingFields.push("pick a mount");
        } else if (selectedMountOption === "A") {
          if (!selectedFasciaInches) {
            newFormattedMissingFields.push("Soffit Depth");
            missingFields.push("fascia inches");
          }
        }
      }
      if (!stepDownFeet) {
        newFormattedMissingFields.push("Step Down Feet");
        missingFields.push("stepdown feet");
      }

      if (!stepDownInches) {
        newFormattedMissingFields.push("Step Down Inches");
        missingFields.push("stepdown inches");
      }
    }
    if (!frameOption) {
      newFormattedMissingFields.push("Frame Option");
      missingFields.push("frame");
    }
    if (!fasciaTrimOption) {
      newFormattedMissingFields.push("Fascia Trim Option");
      missingFields.push("fascia-trim");
    }
    if (!exteriorKpOption) {
      newFormattedMissingFields.push("Exterior KP Option");
      missingFields.push("exterior-kp");
    }
    if (!interiorKpOption) {
      newFormattedMissingFields.push("Interior KP Option");
      missingFields.push("interior-kp");
    }

    if (!selectedRoomStyle) {
      newFormattedMissingFields.push("Room Style");
      missingFields.push("ROOM STYLE");
    }

    if (!selectedBackWallPanels) {
      newFormattedMissingFields.push("Back Wall Panels");
      missingFields.push("back wall panels");
    }
    if (!isScreenRoom) {
      if (!selectedTopTransomOption) {
        newFormattedMissingFields.push("Top Transom Option");
        missingFields.push("Top Transom Option");
      }

      if (selectedSunRoomType !== "Premier View") {
        if (!selectedBotTransom) {
          newFormattedMissingFields.push("Bottom Transom");
          missingFields.push("Bottom Transom");
        }
      }

      if (selectedBotTransom === "yes") {
        if (!selectedBotTransomOption) {
          newFormattedMissingFields.push("Bottom Transom Option");
          missingFields.push("Bottom Transom Option");
        }
      }
    }

    if (!isScreenRoom) {
      if (!selectedWindowWallStyle) {
        newFormattedMissingFields.push("Window Wall Style");
        missingFields.push("Window Wall Style");
      }
    }

    if (!selectedElectricalOption) {
      newFormattedMissingFields.push("Electrical Option");
      missingFields.push("ELECTRICAL YES or NO");
    }

    if (!selectedVertChannelOption) {
      newFormattedMissingFields.push("Vert Channel Option");
      missingFields.push("vert channel");
    }

    setMissingFields(missingFields);
    setFormattedMissingFields(newFormattedMissingFields);
  }, [
    isScreenRoom,
    selectedSunRoomType,
    sashOption,
    selectedTopCustomReady,
    selectedAngled,
    selectedSkinType,
    selectedRoomStyle,
    selectedACLeftOption,
    selectedACCenterOption,
    selectedACRightOption,
    selectedRoofDensity,
    selectedSkyviewChoice,
    selectedValueSeries,
    lengthFeet,
    widthFeet,
    lengthInches,
    widthInches,
    selectedRoofLoadChange,
    selectedFanBeam,
    selectedWindLoadChange,
    heightAtHouse.heightfeet,
    heightAtHouse.heightinches,
    frontHeight.frontfeet,
    frontHeight.frontinches,
    personalInfo,
    isValidEmail,
    locations,
    selectedPermitOption,
    frameOption,
    fasciaTrimOption,
    exteriorKpOption,
    interiorKpOption,
    selectedBackWallPanels,
    selectedElectricalOption,
    selectedVertChannelOption,
    selectedAngleOption,
    doorStraightWidth,
    selectedDoorHardware,
    SSHinges,
    wallStraightLengthsFeet,
    wallStraightLengthsInches,
    doorStraightOption,
    selectedStraightCodes,
    selectedStraightSwingDoorOrientation,
    selectedStraightPatioDoorOrientation,
    selectedPavilionLayoutOption,
    selectedBreezeWay,
    selectedRailSideOption,
    selectedRailAmountOption.feet,
    selectedRailAmountOption.inches,
    doorERFWidth,
    doorERRWidth,
    doorERLWidth,
    wallERLLengthsFeet,
    wallERLLengthsInches,
    doorERLOption,
    selectedERLSwingDoorOrientation,
    selectedERLCodes,
    selectedERLLocations,
    selectedERLPatioDoorOrientation,
    wallERRLengthsFeet,
    wallERRLengthsInches,
    doorERROption,
    selectedERRSwingDoorOrientation,
    selectedERRCodes,
    selectedERRLocations,
    selectedERRPatioDoorOrientation,
    wallERFLengthsFeet,
    wallERFLengthsInches,
    doorERFOption,
    selectedERFSwingDoorOrientation,
    selectedERFCodes,
    selectedERFLocations,
    selectedERFPatioDoorOrientation,
    wallLengthsFeet,
    wallLengthsInches,
    doorOption,
    doorWidth,
    selectedSwingDoorOrientation,
    selectedCodes,
    selectedLocations,
    selectedPatioDoorOrientation,
    selectedRoofPanelColorOption,
    selectedLEDLightChoice,
    selectedShingle,
    selectedCleatsOrBond,
    selectedLocation,
    initialSteelPiers,
    selectedFoundationOption,
    selectedKneeWallOption,
    stepDownFeet,
    stepDownInches,
    selectedNewFoundationOption,
    selectedExistingFoundationOption,
    gradeHeightInches,
    gradeHeightFeet,
    deckFlashingOption,
    deckWidthFeetOption,
    deckWidthInchesOption,
    deckLengthFeetOption,
    deckLengthInchesOption,
    selectedKneeWallFeet,
    selectedKneeWallInches,
    selectedMountOption,
    selectedFasciaInches,
    selectedTopTransomOption,
    selectedBotTransom,
    selectedBotTransomOption,
    selectedWindowWallStyle,
    chairRailWidthFeet,
    chairRailWidthInches,
    bottomKPWidthFeet,
    bottomKPWidthInches,
    selectedTEMOCornersOption,
    screenDoorWidth,
    vinylOption,
    glassRows,
    bays,
  ]);

  // Run the missing fields check whenever form inputs change
  useEffect(() => {
    checkMissingFields();
  }, [
    checkMissingFields,
    selectedPermitOption,
    selectedFoundationOption,
    selectedKneeWallOption,
    selectedMountOption,
    personalInfo,
    selectedExistingFoundationOption,
    selectedNewFoundationOption,
    gradeHeightFeet,
    gradeHeightInches,
    stepDownInches,
    stepDownFeet,
    selectedWindLoadChange,
    selectedRoofLoadChange,
    widthFeet,
    widthInches,
    lengthFeet,
    lengthInches,
    selectedKneeWallFeet,
    selectedKneeWallInches,
    selectedFasciaInches,
    selectedShingle,
    selectedFanBeam,
    selectedRoofDensity,
    heightAtHouse,
    frontHeight,
    selectedSkyviewChoice,
    selectedValueSeries,
    selectedLEDLightChoice,
    deckFlashingOption,
    selectedElectricalOption,
    selectedVertChannelOption,
    selectedWindowWallStyle,
    selectedBackWallPanels,
    selectedBotTransom,
    selectedBotTransomOption,
    selectedTopTransomOption,
    selectedCleatsOrBond,
    selectedRoomStyle,
    frameOption,
    fasciaTrimOption,
    exteriorKpOption,
    interiorKpOption,
    selectedRoofPanelColorOption,
    selectedACLeftOption,
    selectedACCenterOption,
    selectedACRightOption,
    selectedCodes,
    selectedSwingDoorOrientation,
    selectedPatioDoorOrientation,
    selectedDoorHardware,
    doorWidth,
    doorOption,
    wallLengthsFeet,
    wallLengthsInches,
    SSHinges,
    selectedSkinType,
    selectedPavilionLayoutOption,
    selectedRailSideOption,
    selectedRailAmountOption,
    selectedSunRoomType,
    initialSteelPiers,
    deckWidthInchesOption,
    deckLengthInchesOption,
    deckWidthFeetOption,
    deckLengthFeetOption,
    selectedLocation,
    locations,
    selectedBreezeWay,
    selectedTopCustomReady,
    wallERLLengthsFeet,
    wallERLLengthsInches,
    wallERRLengthsFeet,
    wallERRLengthsInches,
    wallERFLengthsFeet,
    wallERFLengthsInches,
    doorERFOption,
    doorERROption,
    doorERLOption,
    doorERFWidth,
    doorERRWidth,
    doorERLWidth,
    selectedERLPatioDoorOrientation,
    selectedERLSwingDoorOrientation,
    selectedERRPatioDoorOrientation,
    selectedERRSwingDoorOrientation,
    selectedERFPatioDoorOrientation,
    selectedERFSwingDoorOrientation,
    selectedERLCodes,
    selectedERRCodes,
    selectedERFCodes,
    selectedAngleOption,
    wallStraightLengthsInches,
    wallStraightLengthsFeet,
    doorStraightOption,
    doorStraightWidth,
    selectedStraightSwingDoorOrientation,
    selectedStraightPatioDoorOrientation,
    selectedStraightCodes,
    selectedAngled,
    sashOption,
    selectedERFLocations,
    selectedERLLocations,
    selectedERRLocations,
    selectedLocations,
    chairRailWidthFeet,
    chairRailWidthInches,
    bottomKPWidthFeet,
    bottomKPWidthInches,
    selectedTEMOCornersOption,
    screenDoorWidth,
    vinylOption,
    glassRows,
    bays,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newMissingFields = [];

    if (selectedSunRoomType === "Solarium") {
      if (!bays) {
        newMissingFields.push("Bays");
      }
      if (!glassRows) {
        newMissingFields.push("Glass Rows");
      }
    }

    if (selectedRoomStyle === "Existing Roof") {
      if (!selectedTEMOCornersOption) {
        newMissingFields.push("TEMO CORNERS YES or NO");
      }
    }

    if (selectedSunRoomType === "Vinyl View") {
      if (!vinylOption) {
        newMissingFields.push("Vinyl Color");
      }
    }

    if (selectedSunRoomType === "Linear Screen") {
      if (!chairRailWidthFeet) {
        newMissingFields.push("chair rail feet");
      }
      if (!chairRailWidthInches) {
        newMissingFields.push("chair rail inches");
      }
      if (!bottomKPWidthFeet) {
        newMissingFields.push("bottom kp feet");
      }
      if (!bottomKPWidthInches) {
        newMissingFields.push("bottom kp inches");
      }
    }
    if (
      !isScreenRoom ||
      (isScreenRoom && selectedSunRoomType === "Value View")
    ) {
      if (!sashOption) {
        newMissingFields.push("Sash Option");
      }
    }

    if (!selectedTopCustomReady && selectedSunRoomType !== "Linear Screen") {
      newMissingFields.push("TCR");
    }

    if (!selectedSunRoomType) {
      newMissingFields.push("Sunroom Type");
    }

    if (
      selectedSunRoomType !== "Linear Screen" &&
      selectedSunRoomType !== "Solarium"
    ) {
      if (!selectedAngled) {
        newMissingFields.push("Angled Option");
      }
    }
    if (selectedAngled === "Yes") {
      if (selectedRoomStyle !== "Existing Roof") {
        if (
          !selectedAngleOption.toString() &&
          selectedAngleOption.toString() !== "0"
        ) {
          newMissingFields.push("Angled Layout");
        }
        for (let i = 0; i < doorStraightWidth.length; i++) {
          if (
            doorStraightWidth[i] === "3' Door" ||
            doorStraightWidth[i] === "3.5' Door"
          ) {
            if (!selectedDoorHardware) {
              newMissingFields.push("Door Hardware");
            }
            if (!SSHinges) {
              newMissingFields.push("SS Hinges");
            }
          }
        }
        const wallLengthLabels = [
          {
            feet: "Left Straight Length Feet",
            inches: "Left Straight Length Inches",
            doorOption: "Left Straight Door Option",
            doorWidth: "Left Straight Door Width",
            selectedCodes: "Left Straight Wall Code",
            patioDoor: "Left Straight Patio Door Orientation",
            swingDoor: "Left Straight Swing Door Orientation",
          },
          {
            feet: "Front Length Feet",
            inches: "Front Length Inches",
            doorOption: "Front Door Option",
            doorWidth: "Front Door Width",
            selectedCodes: "Front Wall Code",
            patioDoor: "Front Patio Door Orientation",
            swingDoor: "Front Swing Door Orientation",
          },
          {
            feet: "Right Straight Length Feet",
            inches: "Right Straight Length Inches",
            doorOption: "Right Straight Door Option",
            doorWidth: "Right Straight Door Width",
            selectedCodes: "Right Straight Wall Code",
            patioDoor: "Right Straight Patio Door Orientation",
            swingDoor: "Right Straight Swing Door Orientation",
          },
          {
            feet: "Left Angle Length Feet",
            inches: "Left Angle Length Inches",
            doorOption: "Left Angle Door Option",
            doorWidth: "Left Angle Door Width",
            selectedCodes: "Left Angle Wall Code",
            patioDoor: "Left Angle Patio Door Orientation",
            swingDoor: "Left Angle Swing Door Orientation",
          },
          {
            feet: "Right Angle Length Feet",
            inches: "Right Angle Length Inches",
            doorOption: "Right Angle Door Option",
            doorWidth: "Right Angle Door Width",
            selectedCodes: "Right Angle Wall Code",
            patioDoor: "Right Angle Patio Door Orientation",
            swingDoor: "Right Angle Swing Door Orientation",
          },
        ];

        const angleOptionsForIndex = [
          ["1", "2", "3", "4", "7"],
          ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
          ["1", "2", "7", "8", "9"],
          ["1", "2", "3", "4", "4", "6", "8"],
          ["1", "3", "6", "7", "8", "9", "10"],
        ];

        for (let i = 0; i < wallLengthLabels.length; i++) {
          if (
            angleOptionsForIndex[i].includes(selectedAngleOption.toString())
          ) {
            if (
              !wallStraightLengthsFeet[i] ||
              wallStraightLengthsFeet[i] === ""
            ) {
              newMissingFields.push(wallLengthLabels[i].feet);
            }
            if (
              !wallStraightLengthsInches[i] ||
              wallStraightLengthsInches[i] === ""
            ) {
              newMissingFields.push(wallLengthLabels[i].inches);
            }
            if (
              (wallStraightLengthsFeet[i] > 4 &&
                selectedSunRoomType !== "Premier View") ||
              (wallStraightLengthsFeet[i] >= 5 &&
                selectedSunRoomType === "Premier View")
            ) {
              if (!doorStraightOption[i]) {
                newMissingFields.push(wallLengthLabels[i].doorOption);
              } else if (doorStraightOption[i] === "Yes") {
                if (!doorStraightWidth[i]) {
                  newMissingFields.push(wallLengthLabels[i].doorWidth);
                } else if (
                  doorStraightWidth[i] === "3' Door" ||
                  doorStraightWidth[i] === "3.5' Door"
                ) {
                  if (!selectedStraightCodes[i]) {
                    newMissingFields.push(wallLengthLabels[i].selectedCodes);
                  }
                  if (!selectedStraightSwingDoorOrientation[i]) {
                    newMissingFields.push(wallLengthLabels[i].swingDoor);
                  }
                } else if (
                  doorStraightWidth[i] !== "3' Door" &&
                  doorStraightWidth[i] !== "3.5' Door" &&
                  doorStraightWidth[i] !== ""
                ) {
                  if (!selectedStraightCodes[i]) {
                    newMissingFields.push(wallLengthLabels[i].selectedCodes);
                  }
                  if (!selectedStraightPatioDoorOrientation[i]) {
                    newMissingFields.push(wallLengthLabels[i].patioDoor);
                  }
                }
              }
            } else {
              if (!selectedStraightCodes[i]) {
                newMissingFields.push(wallLengthLabels[i].selectedCodes);
              }
            }
          }
        }
      }
    } else {
      if (!selectedPavilionLayoutOption) {
        newMissingFields.push("Structure Layout");
      }
      if (Number(selectedPavilionLayoutOption) === 2) {
        if (!selectedBreezeWay) {
          newMissingFields.push("Breeze Way");
        }
        if (selectedBreezeWay === "No") {
          if (!selectedRailSideOption) {
            newMissingFields.push("Rail Side");
          }
        }
        if (selectedRoomStyle !== "Existing Roof") {
          if (!selectedRailAmountOption.feet) {
            newMissingFields.push("Rail Amount Feet");
          }
          if (!selectedRailAmountOption.inches) {
            newMissingFields.push("Rail Amount Inches");
          }
        }
      }
      if (
        Number(selectedPavilionLayoutOption) === 3 &&
        selectedRoomStyle !== "Existing Roof"
      ) {
        if (!selectedRailAmountOption.feet) {
          newMissingFields.push("Rail Amount Feet");
        }
        if (!selectedRailAmountOption.inches) {
          newMissingFields.push("Rail Amount Inches");
        }
      }
    }

    if (!selectedSkinType) {
      newMissingFields.push("Skin Type");
    }

    if (!selectedACLeftOption) {
      newMissingFields.push("LeftProj");
    }
    if (!selectedACCenterOption) {
      newMissingFields.push("FrontWall");
    }
    if (!selectedACRightOption) {
      newMissingFields.push("RightProj");
    }

    if (selectedRoomStyle === "Existing Roof") {
      let addedDoorHardware = false;
      let addedSSHinges = false;

      for (let i = 0; i < doorERFWidth.length; i++) {
        if (doorERFWidth[i] === "3' Door" || doorERFWidth[i] === "3.5' Door") {
          if (!selectedDoorHardware && !addedDoorHardware) {
            newMissingFields.push("Door Hardware");
            addedDoorHardware = true;
          }
          if (!SSHinges && !addedSSHinges) {
            newMissingFields.push("SS Hinges");
            addedSSHinges = true;
          }
        }
      }
      for (let i = 0; i < doorERRWidth.length; i++) {
        if (doorERRWidth[i] === "3' Door" || doorERRWidth[i] === "3.5' Door") {
          if (!selectedDoorHardware && !addedDoorHardware) {
            newMissingFields.push("Door Hardware");
            addedDoorHardware = true;
          }
          if (!SSHinges && !addedSSHinges) {
            newMissingFields.push("SS Hinges");
            addedSSHinges = true;
          }
        }
      }
      for (let i = 0; i < doorERLWidth.length; i++) {
        if (doorERLWidth[i] === "3' Door" || doorERLWidth[i] === "3.5' Door") {
          if (!selectedDoorHardware && !addedDoorHardware) {
            newMissingFields.push("Door Hardware");
            addedDoorHardware = true;
          }
          if (!SSHinges && !addedSSHinges) {
            newMissingFields.push("SS Hinges");
            addedSSHinges = true;
          }
        }
      }
      if (
        Number(selectedPavilionLayoutOption) === 1 ||
        (Number(selectedPavilionLayoutOption) === 2 &&
          selectedBreezeWay === "Yes") ||
        (Number(selectedPavilionLayoutOption) === 2 &&
          selectedBreezeWay === "No" &&
          selectedRailSideOption === "LEFT")
      ) {
        for (let i = 0; i < wallERLLengthsFeet.length; i++) {
          if (!wallERLLengthsFeet[i] || wallERLLengthsFeet[i] === "") {
            newMissingFields.push(`Left Wall Length Feet ${i + 1}`);
          }
          if (!wallERLLengthsInches[i]) {
            newMissingFields.push(`Left Wall Length Inches ${i + 1}`);
          }
        }
        for (let i = 0; i < wallERLLengthsFeet.length; i++) {
          if (
            (wallERLLengthsFeet[i] >= 4 &&
              selectedSunRoomType !== "Premier View") ||
            (wallERLLengthsFeet[i] >= 5 &&
              selectedSunRoomType === "Premier View")
          ) {
            if (!doorERLOption[i]) {
              newMissingFields.push(`Left Door Option ${i + 1}`);
            } else if (doorERLOption[i] === "Yes") {
              if (!doorERLWidth[i]) {
                newMissingFields.push(`Left Door Width ${i + 1}`);
              } else if (
                doorERLWidth[i] === "3' Door" ||
                doorERLWidth[i] === "3.5' Door"
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERLCodes[i]) {
                    newMissingFields.push(`Left Wall Code ${i + 1}`);
                  }
                } else {
                  if (!selectedERLLocations[i]) {
                    newMissingFields.push(`Left Door Location ${i + 1}`);
                  }
                }
                if (!selectedERLSwingDoorOrientation[i]) {
                  newMissingFields.push(`Left Swing Door Orientation ${i + 1}`);
                }
              } else if (
                doorERLWidth[i] !== "3' Door" &&
                doorERLWidth[i] !== "3.5' Door" &&
                doorERLWidth[i] !== ""
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERLCodes[i]) {
                    newMissingFields.push(`Left Wall Code ${i + 1}`);
                  }
                }
                if (!selectedERLPatioDoorOrientation[i]) {
                  newMissingFields.push(`Left Patio Door Orientation ${i + 1}`);
                }
              }
            } else {
              if (selectedSunRoomType !== "Linear Screen") {
                if (!selectedERLCodes[i]) {
                  newMissingFields.push(`Left Wall Code ${i + 1}`);
                }
              }
            }
          } else {
            if (selectedSunRoomType !== "Linear Screen") {
              if (!selectedERLCodes[i]) {
                newMissingFields.push(`Left Wall Code ${i + 1}`);
              }
            }
          }
        }
      }
      if (
        Number(selectedPavilionLayoutOption) === 1 ||
        (Number(selectedPavilionLayoutOption) === 2 &&
          selectedBreezeWay === "Yes") ||
        (Number(selectedPavilionLayoutOption) === 2 &&
          selectedBreezeWay === "No" &&
          selectedRailSideOption === "RIGHT")
      ) {
        for (let i = 0; i < wallERRLengthsFeet.length; i++) {
          if (!wallERRLengthsFeet[i] || wallERRLengthsFeet[i] === "") {
            newMissingFields.push(`Right Wall Length Feet ${i + 1}`);
          }
          if (!wallERRLengthsInches[i]) {
            newMissingFields.push(`Right Wall Length Inches ${i + 1}`);
          }
        }
        for (let i = 0; i < wallERRLengthsFeet.length; i++) {
          if (
            (wallERRLengthsFeet[i] >= 4 &&
              selectedSunRoomType !== "Premier View") ||
            (wallERRLengthsFeet[i] >= 5 &&
              selectedSunRoomType === "Premier View")
          ) {
            if (!doorERROption[i]) {
              newMissingFields.push(`Right Door Option ${i + 1}`);
            } else if (doorERROption[i] === "Yes") {
              if (!doorERRWidth[i]) {
                newMissingFields.push(`Right Door Width ${i + 1}`);
              } else if (
                doorERRWidth[i] === "3' Door" ||
                doorERRWidth[i] === "3.5' Door"
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERRCodes[i]) {
                    newMissingFields.push(`Right Wall Code ${i + 1}`);
                  }
                } else {
                  if (!selectedERRLocations[i]) {
                    newMissingFields.push(`Right Door Location ${i + 1}`);
                  }
                }
                if (!selectedERRSwingDoorOrientation[i]) {
                  newMissingFields.push(
                    `Right Swing Door Orientation ${i + 1}`,
                  );
                }
              } else if (
                doorERRWidth[i] !== "3' Door" &&
                doorERRWidth[i] !== "3.5' Door" &&
                doorERRWidth[i] !== ""
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERRCodes[i]) {
                    newMissingFields.push(`Right Wall Code ${i + 1}`);
                  }
                }
                if (!selectedERRPatioDoorOrientation[i]) {
                  newMissingFields.push(
                    `Right Patio Door Orientation ${i + 1}`,
                  );
                }
              }
            } else {
              if (selectedSunRoomType !== "Linear Screen") {
                if (!selectedERRCodes[i]) {
                  newMissingFields.push(`Right Wall Code ${i + 1}`);
                }
              }
            }
          } else {
            if (selectedSunRoomType !== "Linear Screen") {
              if (!selectedERRCodes[i]) {
                newMissingFields.push(`Right Wall Code ${i + 1}`);
              }
            }
          }
        }
      }
      if (selectedBreezeWay !== "Yes") {
        for (let i = 0; i < wallERFLengthsFeet.length; i++) {
          if (!wallERFLengthsFeet[i] || wallERFLengthsFeet[i] === "") {
            newMissingFields.push(`Front Wall Length Feet ${i + 1}`);
          }
          if (!wallERFLengthsInches[i]) {
            newMissingFields.push(`Front Wall Length Inches ${i + 1}`);
          }
        }
        for (let i = 0; i < wallERFLengthsFeet.length; i++) {
          if (
            (wallERFLengthsFeet[i] >= 4 &&
              selectedSunRoomType !== "Premier View") ||
            (wallERFLengthsFeet[i] >= 6 &&
              selectedSunRoomType === "Premier View")
          ) {
            if (!doorERFOption[i]) {
              newMissingFields.push(`Front Door Option ${i + 1}`);
            } else if (doorERFOption[i] === "Yes") {
              if (!doorERFWidth[i]) {
                newMissingFields.push(`Front Door Width ${i + 1}`);
              } else if (
                doorERFWidth[i] === "3' Door" ||
                doorERFWidth[i] === "3.5' Door"
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERFCodes[i]) {
                    newMissingFields.push(`Front Wall Code ${i + 1}`);
                  }
                } else {
                  if (!selectedERFLocations[i]) {
                    newMissingFields.push(`Front Door Location ${i + 1}`);
                  }
                }
                if (!selectedERFSwingDoorOrientation[i]) {
                  newMissingFields.push(
                    `Front Swing Door Orientation ${i + 1}`,
                  );
                }
              } else if (
                doorERFWidth[i] !== "3' Door" &&
                doorERFWidth[i] !== "3.5' Door" &&
                doorERFWidth[i] !== ""
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedERFCodes[i]) {
                    newMissingFields.push(`Front Wall Code ${i + 1}`);
                  }
                }
                if (!selectedERFPatioDoorOrientation[i]) {
                  newMissingFields.push(
                    `Front Patio Door Orientation ${i + 1}`,
                  );
                }
              }
            } else {
              if (selectedSunRoomType !== "Linear Screen") {
                if (!selectedERFCodes[i]) {
                  newMissingFields.push(`Front Wall Code ${i + 1}`);
                }
              }
            }
          } else {
            if (selectedSunRoomType !== "Linear Screen") {
              if (!selectedERFCodes[i]) {
                newMissingFields.push(`Front Wall Code ${i + 1}`);
              }
            }
          }
        }
      }
    }
    if (selectedRoomStyle !== "Existing Roof") {
      if (selectedAngled !== "Yes") {
        for (let i = 0; i < wallLengthsFeet.length; i++) {
          if (
            selectedSunRoomType === "Solarium" &&
            (i === 1 || (selectedPavilionLayoutOption === "3" && i === 0))
          ) {
            continue;
          }
          if (!wallLengthsFeet[i] || wallLengthsFeet[i] === "") {
            newMissingFields.push(`Wall Length Feet ${i + 1}`);
          }
          if (!wallLengthsInches[i]) {
            newMissingFields.push(`Wall Length Inches ${i + 1}`);
          }
        }
        let finalScreenDoorWidthAdded = false;

        for (let i = 0; i < wallLengthsFeet.length; i++) {
          if (
            selectedSunRoomType === "Solarium" &&
            (i === 1 || (selectedPavilionLayoutOption === "3" && i === 0))
          ) {
            continue;
          }
          if (wallLengthsFeet[i] >= 4) {
            if (!doorOption[i]) {
              newMissingFields.push(`Door Option ${i + 1}`);
            } else if (doorOption[i] === "Yes") {
              if (selectedSunRoomType === "Linear Screen") {
                if (!screenDoorWidth && !finalScreenDoorWidthAdded) {
                  newMissingFields.push("Screen Door Width");
                  finalScreenDoorWidthAdded = true;
                }
              }
              if (!doorWidth[i]) {
                newMissingFields.push(`Door Width ${i + 1}`);
              } else if (
                doorWidth[i] === "3' Door" ||
                doorWidth[i] === "3.5' Door"
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedCodes[i]) {
                    newMissingFields.push(`Wall Code ${i + 1}`);
                  }
                } else {
                  if (!selectedLocations[i]) {
                    newMissingFields.push(`Door Location ${i + 1}`);
                  }
                }
                if (!selectedSwingDoorOrientation[i]) {
                  newMissingFields.push(`Swing Door Orientation ${i + 1}`);
                }
                if (!selectedDoorHardware) {
                  newMissingFields.push("Door Hardware");
                }
                if (!SSHinges) {
                  newMissingFields.push("SS Hinges");
                }
              } else if (
                doorWidth[i] !== "3' Door" &&
                doorWidth[i] !== "3.5' Door" &&
                doorWidth[i] !== ""
              ) {
                if (selectedSunRoomType !== "Linear Screen") {
                  if (!selectedCodes[i]) {
                    newMissingFields.push(`Wall Code ${i + 1}`);
                  }
                }
                if (!selectedPatioDoorOrientation[i]) {
                  newMissingFields.push(`Patio Door Orientation ${i + 1}`);
                }
              }
            } else {
              if (selectedSunRoomType !== "Linear Screen") {
                if (!selectedCodes[i]) {
                  newMissingFields.push(`Wall Code ${i + 1}`);
                }
              }
            }
          } else {
            if (selectedSunRoomType !== "Linear Screen") {
              if (!selectedCodes[i]) {
                newMissingFields.push(`Wall Code ${i + 1}`);
              }
            }
          }
        }
      }
      if (
        selectedRoofDensity !== '4 1/4" 0.032 1 pound' &&
        selectedRoofDensity !== '4 1/4" 0.032 2 pound' &&
        selectedSkyviewChoice !== "Skyview YES"
      ) {
        if (!selectedRoofPanelColorOption) {
          newMissingFields.push("Roof Panel Color");
        }
      }
      if (
        selectedRoofDensity === '4 1/4" 0.032 1 pound' ||
        selectedRoofDensity === '4 1/4" 0.032 2 pound'
      ) {
        selectedRoofPanelColorOption = "";
      }

      if (selectedSunRoomType !== "Solarium") {
        if (!selectedValueSeries) {
          newMissingFields.push("Value Series");
        }
      }
      if (
        selectedRoomStyle !== "CATHEDRAL" &&
        selectedRoomStyle !== "Existing Roof" &&
        selectedSunRoomType !== "Solarium" &&
        selectedAngled !== "Yes"
      ) {
        if (!selectedSkyviewChoice) {
          newMissingFields.push("Skyview YES or NO");
        }
      }
      if (selectedSkyviewChoice === "Skyview YES") {
        if (!selectedLEDLightChoice) {
          newMissingFields.push("LED YES or NO");
        }
        selectedCleatsOrBond = "";
        selectedShingle = "";
        selectedRoofPanelColorOption = "";
      }
      if (
        selectedSkyviewChoice !== "Skyview YES" &&
        selectedSunRoomType !== "Solarium"
      ) {
        selectedLEDLightChoice = "";
        if (!selectedShingle) {
          newMissingFields.push("Shingle Roof");
        }
        if (selectedShingle !== "YES") {
          if (!selectedCleatsOrBond) {
            newMissingFields.push("Cleats or Bond Tape");
          }
        }
      }
      if (!selectedRoofLoadChange) {
        newMissingFields.push("RoofLoad");
      }
      if (!lengthFeet || lengthFeet < 4) {
        newMissingFields.push("length feet");
      }
      if (!lengthInches) {
        newMissingFields.push("length inches");
      }
      if (selectedSunRoomType !== "Solarium") {
        if (!widthFeet || widthFeet < 4) {
          newMissingFields.push("width feet");
        }
        if (!widthInches) {
          newMissingFields.push("width inches");
        }
      }
      if (!selectedFanBeam) {
        newMissingFields.push("fan beam");
      }
      if (!selectedRoofDensity) {
        newMissingFields.push("roof density");
      }
    }
    if (!isScreenRoom) {
      if (!selectedWindowWallStyle) {
        newMissingFields.push("Window Wall Style");
      }
    }

    if (!selectedElectricalOption) {
      newMissingFields.push("ELECTRICAL YES or NO");
    }
    if (!selectedVertChannelOption) {
      newMissingFields.push("vert channel");
    }

    if (!selectedWindLoadChange) {
      newMissingFields.push("WindLoad");
    }

    if (!heightAtHouse.heightfeet) {
      newMissingFields.push("Height at House Heightfeet");
    }

    if (!heightAtHouse.heightinches) {
      newMissingFields.push("Height at House Heightinches");
    }

    if (!frontHeight.frontfeet) {
      newMissingFields.push("Front Height Frontfeet");
    }

    if (!frontHeight.frontinches) {
      newMissingFields.push("Front Height Frontinches");
    }
    for (const key in personalInfo) {
      if (key === "firstName" && personalInfo[key] === "") {
        continue;
      }
      if (key === "purchaseOrder" && personalInfo[key] === "") {
        continue;
      }
      if (!personalInfo[key]) {
        newMissingFields.push(key);
      }
    }
    if (personalInfo["email"] && !isValidEmail) {
      newMissingFields.push("email");
    }
    if (locations && Object.keys(locations).length > 0) {
      if (!selectedLocation) {
        newMissingFields.push("location");
      }
    }
    if (!selectedPermitOption) {
      newMissingFields.push("permit package option");
    }
    if (selectedPermitOption === "YES") {
      if (!initialSteelPiers) {
        newMissingFields.push("Steel Piers");
      }
      if (!selectedFoundationOption) {
        newMissingFields.push("foundation option");
      }
      if (selectedFoundationOption === "newconcrete") {
        if (!selectedNewFoundationOption) {
          newMissingFields.push("Pick a new concrete option");
        }
      }
      if (selectedFoundationOption === "existingconcrete") {
        if (!selectedExistingFoundationOption) {
          newMissingFields.push("Pick an existing slab option");
        }
      }

      if (
        selectedFoundationOption === "existingwood" ||
        selectedFoundationOption === "nontemowood" ||
        selectedFoundationOption === "temodeck"
      ) {
        if (!gradeHeightInches) {
          newMissingFields.push("Please input inches grade height");
        }
        if (!gradeHeightFeet) {
          newMissingFields.push("Please input feet grade height");
        }
      }
      if (selectedFoundationOption === "temodeck") {
        if (!deckFlashingOption) {
          newMissingFields.push("deck flashing option");
        }
        if (!deckWidthFeetOption) {
          newMissingFields.push("deck width feet");
        }
        if (!deckWidthInchesOption) {
          newMissingFields.push("deck width inches");
        }
        if (!deckLengthFeetOption) {
          newMissingFields.push("deck length feet");
        }
        if (!deckLengthInchesOption) {
          newMissingFields.push("deck length inches");
        }
      }
      if (!selectedKneeWallOption) {
        newMissingFields.push("knee wall option");
      } else if (selectedKneeWallOption === "yes") {
        if (!selectedKneeWallFeet) {
          newMissingFields.push("Select Knee Wall Feet");
        }
        if (!selectedKneeWallInches) {
          newMissingFields.push("Select Knee Wall Inches");
        }
      } else if (selectedKneeWallOption === "no") {
        selectedKneeWallInches = "";
        selectedKneeWallFeet = "";
      }
      if (selectedRoomStyle !== "Existing Roof") {
        if (!selectedMountOption) {
          newMissingFields.push("pick a mount");
        } else if (selectedMountOption === "A") {
          if (!selectedFasciaInches) {
            newMissingFields.push("fascia inches");
          }
        }
      }
      if (!stepDownFeet) {
        newMissingFields.push("stepdown feet");
      }

      if (!stepDownInches) {
        newMissingFields.push("stepdown inches");
      }
    }
    if (selectedPermitOption === "NO") {
      selectedFoundationOption = "";
      selectedNewFoundationOption = "";
      selectedExistingFoundationOption = "";
      gradeHeightInches = "";
      gradeHeightFeet = "";
      selectedKneeWallOption = "";
      selectedKneeWallFeet = "";
      selectedKneeWallInches = "";
      selectedMountOption = "";
      selectedFasciaInches = "";
      stepDownFeet = "";
      stepDownInches = "";
    }
    if (!frameOption) {
      newMissingFields.push("frame");
    }
    if (!fasciaTrimOption) {
      newMissingFields.push("fascia-trim");
    }
    if (!exteriorKpOption) {
      newMissingFields.push("exterior-kp");
    }
    if (!interiorKpOption) {
      newMissingFields.push("interior-kp");
    }

    if (!selectedRoomStyle) {
      newMissingFields.push("ROOM STYLE");
    }

    if (!selectedBackWallPanels) {
      newMissingFields.push("back wall panels");
    }

    if (!isScreenRoom) {
      if (!selectedTopTransomOption) {
        newMissingFields.push("Top Transom Option");
      }

      if (selectedSunRoomType !== "Premier View") {
        if (!selectedBotTransom) {
          newMissingFields.push("Bottom Transom");
        }
      }

      if (selectedBotTransom === "yes") {
        if (!selectedBotTransomOption) {
          newMissingFields.push("Bottom Transom Option");
        }
      }
    }
    if (newMissingFields.length === 0) {
      const data = {
        SunroomsData: {
          selectedPermitOption,
          selectedFoundationOption,
          selectedKneeWallOption,
          selectedMountOption,
          personalInfo,
          selectedExistingFoundationOption,
          selectedNewFoundationOption,
          gradeHeightFeet,
          gradeHeightInches,
          stepDownInches,
          stepDownFeet,
          selectedWindLoadChange,
          selectedRoofLoadChange,
          widthFeet,
          widthInches,
          lengthFeet,
          lengthInches,
          selectedKneeWallFeet,
          selectedKneeWallInches,
          selectedFasciaInches,
          selectedShingle,
          selectedFanBeam,
          selectedRoofDensity,
          heightAtHouse,
          frontHeight,
          selectedSkyviewChoice,
          selectedValueSeries,
          selectedLEDLightChoice,
          deckFlashingOption,
          selectedElectricalOption,
          selectedVertChannelOption,
          selectedWindowWallStyle,
          selectedBackWallPanels,
          selectedBotTransomOption,
          selectedTopTransomOption,
          selectedCleatsOrBond,
          selectedRoomStyle,
          frameOption,
          fasciaTrimOption,
          exteriorKpOption,
          interiorKpOption,
          selectedRoofPanelColorOption,
          selectedACLeftOption,
          selectedACCenterOption,
          selectedACRightOption,
          selectedCodes,
          selectedSwingDoorOrientation,
          selectedPatioDoorOrientation,
          selectedDoorHardware,
          wallLengthsFeet,
          wallLengthsInches,
          SSHinges,
          selectedSkinType,
          selectedPavilionLayoutOption,
          selectedRailSideOption,
          selectedRailAmountOption,
          additionalInfo,
          selectedSunRoomType,
          initialSteelPiers,
          deckWidthInchesOption,
          deckLengthInchesOption,
          deckWidthFeetOption,
          deckLengthFeetOption,
          selectedTopCustomReady,
          selectedLocation,
          wallERLLengthsFeet,
          wallERLLengthsInches,
          wallERRLengthsFeet,
          wallERRLengthsInches,
          wallERFLengthsFeet,
          wallERFLengthsInches,
          doorERFOption,
          doorERROption,
          doorERLOption,
          doorERFWidth,
          doorERRWidth,
          doorERLWidth,
          selectedERLPatioDoorOrientation,
          selectedERLSwingDoorOrientation,
          selectedERRPatioDoorOrientation,
          selectedERRSwingDoorOrientation,
          selectedERFPatioDoorOrientation,
          selectedERFSwingDoorOrientation,
          selectedERLCodes,
          selectedERRCodes,
          selectedERFCodes,
          selectedAngleOption,
          wallStraightLengthsInches,
          wallStraightLengthsFeet,
          doorStraightOption,
          doorStraightWidth,
          selectedStraightSwingDoorOrientation,
          selectedStraightPatioDoorOrientation,
          selectedStraightCodes,
          selectedAngled,
          leftAngleFeet,
          leftAngleInches,
          rightAngleFeet,
          rightAngleInches,
          sashOption,
          selectedERFLocations,
          selectedERLLocations,
          selectedERRLocations,
          selectedLocations,
          chairRailWidthFeet,
          chairRailWidthInches,
          bottomKPWidthFeet,
          bottomKPWidthInches,
          selectedTEMOCornersOption,
          screenDoorWidth,
          vinylOption,
          glassRows,
          bays,
        },
      };

      setSubmitIsLoading(true);

      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });
      const jsonData = JSON.stringify(data);

      fetch("/api/OrderingSunrooms/SubmitSunroom", {
        method: "POST",
        body: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            // return response.json().then((data) => {
            //   setCurrentCADImagesFiles(data.fileName);
            setEndMessage(<SuccessMessage />);
            setSubmitIsLoading(false);
            setSentCorrectly(true);
            // });
          } else if (response.status === 999) {
            setSubmitIsLoading(false);
            setEndMessage(<HoldErrorMessage />);
          } else if (response.status === 998) {
            setSubmitIsLoading(false);
            setEndMessage(<RepErrorMessage />);
          } else if (response.status === 997) {
            setSubmitIsLoading(false);
            setEndMessage(<SunroomLockMessage />);
          } else {
            setSubmitIsLoading(false);
            setEndMessage(<ErrorMessage />);
          }
          setShowEndMessage(true);
        })
        .catch((error) => {
          console.error("Error sending data:", error);
          setSubmitIsLoading(false);
          setEndMessage(<ErrorMessage />);
          setShowEndMessage(true);
        });
      closeModal();
    } else {
      setFormattedMissingFields(formattedMissingFields);
      closeModal();
    }
  };

  return (
    <div className="popupwindow submit-stuff">
      <div style={{ display: "flex", flexDirection: "column" }}>
        {showEndMessage && <div>{endMessage}</div>}
        {showMissingFields && formattedMissingFields.length > 0 && (
          <MissingFieldsBox missingFields={formattedMissingFields} />
        )}
        {/* <DrawingOverlaySelection
          currentCADImagesFiles={currentCADImagesFiles}
          drawingImages={drawingImages}
          setDrawingImages={setDrawingImages}
          setIsDrawingOverlayOpen={setIsDrawingOverlayOpen}
          sentCorrectly={sentCorrectly}
          isDrawingOverlayOpen={isDrawingOverlayOpen}
        /> */}
        {submitIsLoading && <div>Loading...</div>}
      </div>
      <form id="submitbutton">
        {sentCorrectly !== true && submitIsLoading !== true && (
          <>
            <span className="realredasterisk">
              <div className="fontsizeforinputsrow" style={{ fontSize: "2vh" }}>
                Any&nbsp;Area&nbsp;With&nbsp;This&nbsp;&#10088;
                <b>
                  <span className="redasterisk">&#10033;</span>
                </b>
                &#10089;&nbsp;Is
              </div>
              <div
                className="fontsizeforinputsrow"
                style={{ textAlign: "center", fontSize: "2vh" }}
              >
                <u>
                  <b>REQUIRED</b>
                </u>
                &nbsp;Before&nbsp;Submitting
              </div>
            </span>
            <input
              id="submit"
              type="submit"
              className="showpdfbuttons submitbutton"
              onClick={openModal}
              value="Send to TEMO"
            />
          </>
        )}
      </form>
      {showModal && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            height: "100%",
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(34, 43, 43, .9)",
          }}
        >
          <div
            className="popupwindow"
            style={{
              height: "86vh",
              width: "45vw",
              display: "flex",
              flexDirection: "column",
              backgroundImage: "linear-gradient(to bottom, #09b9e148, #09b8e1)",
            }}
          >
            <div
              style={{
                height: "10%",
                backgroundColor: "#9ecb6a",
                top: 0,
                marginTop: 0,
                border: "1px solid black",
              }}
            >
              <h5>Are you sure you want to submit?</h5>
              <h5>These are your selections:</h5>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "white",
                overflow: "auto",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                  margin: "0",
                  width: "50%",
                  height: "100%",
                }}
              >
                {selectedAngled && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Angled Option:</p>
                    <p className="modalrowright">
                      {selectedAngled && selectedAngled}
                    </p>
                  </div>
                )}
                {selectedAngled !== "No" &&
                  selectedAngled !== "" &&
                  selectedAngleOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Angled Layout:</p>
                      <p className="modalrowright">{selectedAngleOption}</p>
                    </div>
                  )}
                {selectedStraightCodes[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Wall Codes:</p>
                    <p className="modalrowright">
                      {selectedStraightCodes[0]}, {selectedStraightCodes[1]},
                      {selectedStraightCodes[2]}, {selectedStraightCodes[3]},
                      {selectedStraightCodes[5]}
                    </p>
                  </div>
                )}
                {wallStraightLengthsFeet[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Wall Lengths:</p>
                    <p className="modalrowright">
                      {wallStraightLengthsFeet[0] &&
                        wallStraightLengthsFeet[0] +
                          "'" +
                          " " +
                          wallStraightLengthsInches[0] +
                          '"'}
                      ,&nbsp;
                      {wallStraightLengthsFeet[1] &&
                        wallStraightLengthsFeet[1] +
                          "'" +
                          " " +
                          wallStraightLengthsInches[1] +
                          '"'}
                      ,&nbsp;
                      {wallStraightLengthsFeet[2] &&
                        wallStraightLengthsFeet[2] +
                          "'" +
                          " " +
                          wallStraightLengthsInches[2] +
                          '"'}
                      ,&nbsp;
                      {wallStraightLengthsFeet[3] &&
                        wallStraightLengthsFeet[3] +
                          "'" +
                          " " +
                          wallStraightLengthsInches[3] +
                          '"'}
                      ,&nbsp;
                      {wallStraightLengthsFeet[4] &&
                        wallStraightLengthsFeet[4] +
                          "'" +
                          " " +
                          wallStraightLengthsInches[4] +
                          '"'}
                    </p>
                  </div>
                )}
                {doorStraightOption[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Door Option:</p>
                    <p className="modalrowright">
                      {doorStraightOption[0] && doorStraightOption[0]}
                      ,&nbsp;
                      {doorStraightOption[1] && doorStraightOption[1]}
                      ,&nbsp;
                      {doorStraightOption[2] && doorStraightOption[2]}
                      ,&nbsp;
                      {doorStraightOption[3] && doorStraightOption[3]}
                      ,&nbsp;
                      {doorStraightOption[4] && doorStraightOption[4]}
                    </p>
                  </div>
                )}
                {isScreenRoom && screenDoorWidth && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Screen Door Width:</p>
                    <p className="modalrowright">{screenDoorWidth}</p>
                  </div>
                )}
                {doorStraightWidth[0] && !isScreenRoom && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Door Width:</p>
                    <p className="modalrowright">
                      {doorStraightWidth[0] && doorStraightWidth[0]}
                      ,&nbsp;
                      {doorStraightWidth[1] && doorStraightWidth[1]}
                      ,&nbsp;
                      {doorStraightWidth[2] && doorStraightWidth[2]}
                      ,&nbsp;
                      {doorStraightWidth[3] && doorStraightWidth[3]}
                      ,&nbsp;
                      {doorStraightWidth[4] && doorStraightWidth[4]}
                    </p>
                  </div>
                )}
                {selectedStraightSwingDoorOrientation[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Swing Door Orientation:</p>
                    <p className="modalrowright">
                      {selectedStraightSwingDoorOrientation[0] &&
                        selectedStraightSwingDoorOrientation[0]}
                      ,&nbsp;
                      {selectedStraightSwingDoorOrientation[1] &&
                        selectedStraightSwingDoorOrientation[1]}
                      ,&nbsp;
                      {selectedStraightSwingDoorOrientation[2] &&
                        selectedStraightSwingDoorOrientation[2]}
                      ,&nbsp;
                      {selectedStraightSwingDoorOrientation[3] &&
                        selectedStraightSwingDoorOrientation[3]}
                      ,&nbsp;
                      {selectedStraightSwingDoorOrientation[4] &&
                        selectedStraightSwingDoorOrientation[4]}
                    </p>
                  </div>
                )}
                {selectedStraightPatioDoorOrientation[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Patio Door Orientation:</p>
                    <p className="modalrowright">
                      {selectedStraightPatioDoorOrientation[0] &&
                        selectedStraightPatioDoorOrientation[0]}
                      ,&nbsp;
                      {selectedStraightPatioDoorOrientation[1] &&
                        selectedStraightPatioDoorOrientation[1]}
                      ,&nbsp;
                      {selectedStraightPatioDoorOrientation[2] &&
                        selectedStraightPatioDoorOrientation[2]}
                      ,&nbsp;
                      {selectedStraightPatioDoorOrientation[3] &&
                        selectedStraightPatioDoorOrientation[3]}
                      ,&nbsp;
                      {selectedStraightPatioDoorOrientation[4] &&
                        selectedStraightPatioDoorOrientation[4]}
                    </p>
                  </div>
                )}
                {selectedLocations[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Door Locations:</p>
                    <p className="modalrowright">
                      {selectedLocations[0] && selectedLocations[0]}
                      ,&nbsp;
                      {selectedLocations[1] && selectedLocations[1]}
                      ,&nbsp;
                      {selectedLocations[2] && selectedLocations[2]}
                      ,&nbsp;
                      {selectedLocations[3] && selectedLocations[3]}
                    </p>
                  </div>
                )}
                {selectedCodes[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Wall Codes:</p>
                    <p className="modalrowright">
                      {selectedCodes[0]}, {selectedCodes[1]}, {selectedCodes[2]}
                    </p>
                  </div>
                )}
                {selectedERLCodes[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Left Wall Codes:</p>
                    <p className="modalrowright">
                      {selectedERLCodes[0] && selectedERLCodes[0]}
                      ,&nbsp;
                      {selectedERLCodes[1] && selectedERLCodes[1]}
                      ,&nbsp;
                      {selectedERLCodes[2] && selectedERLCodes[2]}
                    </p>
                  </div>
                )}
                {selectedERRCodes[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Right Wall Codes:</p>
                    <p className="modalrowright">
                      {selectedERRCodes[0] && selectedERRCodes[0]}
                      ,&nbsp;
                      {selectedERRCodes[1] && selectedERRCodes[1]}
                      ,&nbsp;
                      {selectedERRCodes[2] && selectedERRCodes[2]}
                    </p>
                  </div>
                )}
                {selectedERFCodes[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Front Wall Codes:</p>
                    <p className="modalrowright">
                      {selectedERFCodes[0] && selectedERFCodes[0]}
                      ,&nbsp;
                      {selectedERFCodes[1] && selectedERFCodes[1]}
                      ,&nbsp;
                      {selectedERFCodes[2] && selectedERFCodes[2]}
                      ,&nbsp;
                      {selectedERFCodes[3] && selectedERFCodes[3]}
                      ,&nbsp;
                      {selectedERFCodes[4] && selectedERFCodes[4]}
                      ,&nbsp;
                      {selectedERFCodes[5] && selectedERFCodes[5]}
                    </p>
                  </div>
                )}
                {selectedERFLocations[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Front Door Locations:</p>
                    <p className="modalrowright">
                      {selectedERFLocations[0] && selectedERFLocations[0]}
                      ,&nbsp;
                      {selectedERFLocations[1] && selectedERFLocations[1]}
                      ,&nbsp;
                      {selectedERFLocations[2] && selectedERFLocations[2]}
                      ,&nbsp;
                      {selectedERFLocations[3] && selectedERFLocations[3]}
                      ,&nbsp;
                      {selectedERFLocations[4] && selectedERFLocations[4]}
                      ,&nbsp;
                      {selectedERFLocations[5] && selectedERFLocations[5]}
                    </p>
                  </div>
                )}

                {selectedERLLocations[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Left Door Locations:</p>
                    <p className="modalrowright">
                      {selectedERLLocations[0] && selectedERLLocations[0]}
                      ,&nbsp;
                      {selectedERLLocations[1] && selectedERLLocations[1]}
                      ,&nbsp;
                      {selectedERLLocations[2] && selectedERLLocations[2]}
                    </p>
                  </div>
                )}

                {selectedERRLocations[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Right Door Locations:</p>
                    <p className="modalrowright">
                      {selectedERRLocations[0] && selectedERRLocations[0]}
                      ,&nbsp;
                      {selectedERRLocations[1] && selectedERRLocations[1]}
                      ,&nbsp;
                      {selectedERRLocations[2] && selectedERRLocations[2]}
                    </p>
                  </div>
                )}

                {wallERLLengthsFeet[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Left Wall Lengths:</p>
                    <p className="modalrowright">
                      {wallERLLengthsFeet[0] &&
                        wallERLLengthsFeet[0] +
                          "'" +
                          " " +
                          wallERLLengthsInches[0] +
                          '"'}
                      ,&nbsp;
                      {wallERLLengthsFeet[1] &&
                        wallERLLengthsFeet[1] +
                          "'" +
                          " " +
                          wallERLLengthsInches[1] +
                          '"'}
                      ,&nbsp;
                      {wallERLLengthsFeet[2] &&
                        wallERLLengthsFeet[2] +
                          "'" +
                          " " +
                          wallERLLengthsInches[2] +
                          '"'}
                    </p>
                  </div>
                )}
                {wallERRLengthsFeet[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Right Wall Lengths:</p>
                    <p className="modalrowright">
                      {wallERRLengthsFeet[0] &&
                        wallERRLengthsFeet[0] +
                          "'" +
                          " " +
                          wallERRLengthsInches[0] +
                          '"'}
                      ,&nbsp;
                      {wallERRLengthsFeet[1] &&
                        wallERRLengthsFeet[1] +
                          "'" +
                          " " +
                          wallERRLengthsInches[1] +
                          '"'}
                      ,&nbsp;
                      {wallERRLengthsFeet[2] &&
                        wallERRLengthsFeet[2] +
                          "'" +
                          " " +
                          wallERRLengthsInches[2] +
                          '"'}
                      ,&nbsp;
                    </p>
                  </div>
                )}
                {wallERFLengthsFeet[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Front Wall Lengths:</p>
                    <p className="modalrowright">
                      {wallERFLengthsFeet[0] &&
                        wallERFLengthsFeet[0] +
                          "'" +
                          " " +
                          wallERFLengthsInches[0] +
                          '"'}
                      ,&nbsp;
                      {wallERFLengthsFeet[1] &&
                        wallERFLengthsFeet[1] +
                          "'" +
                          " " +
                          wallERFLengthsInches[1] +
                          '"'}
                      ,&nbsp;
                      {wallERFLengthsFeet[2] &&
                        wallERFLengthsFeet[2] +
                          "'" +
                          " " +
                          wallERFLengthsInches[2] +
                          '"'}
                      ,&nbsp;
                      {wallERFLengthsFeet[3] &&
                        wallERFLengthsFeet[3] +
                          "'" +
                          " " +
                          wallERFLengthsInches[3] +
                          '"'}
                      ,&nbsp;
                      {wallERFLengthsFeet[4] &&
                        wallERFLengthsFeet[4] +
                          "'" +
                          " " +
                          wallERFLengthsInches[4] +
                          '"'}
                      ,&nbsp;
                      {wallERFLengthsFeet[5] &&
                        wallERFLengthsFeet[5] +
                          "'" +
                          " " +
                          wallERFLengthsInches[5] +
                          '"'}
                      ,&nbsp;
                    </p>
                  </div>
                )}
                {doorERFWidth[0] && !isScreenRoom && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Front Door Widths:</p>
                    <p className="modalrowright">
                      {doorERFWidth[0] && doorERFWidth[0]}
                      ,&nbsp;
                      {doorERFWidth[1] && doorERFWidth[1]}
                      ,&nbsp;
                      {doorERFWidth[2] && doorERFWidth[2]}
                      ,&nbsp;
                      {doorERFWidth[3] && doorERFWidth[3]}
                      ,&nbsp;
                      {doorERFWidth[4] && doorERFWidth[4]}
                      ,&nbsp;
                      {doorERFWidth[5] && doorERFWidth[5]}
                      ,&nbsp;
                    </p>
                  </div>
                )}
                {doorERRWidth[0] && !isScreenRoom && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Right Door Widths:</p>
                    <p className="modalrowright">
                      {doorERRWidth[0] && doorERRWidth[0]}
                      ,&nbsp;
                      {doorERRWidth[1] && doorERRWidth[1]}
                      ,&nbsp;
                      {doorERRWidth[2] && doorERRWidth[2]}
                    </p>
                  </div>
                )}
                {doorERLWidth[0] && !isScreenRoom && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Left Door Widths:</p>
                    <p className="modalrowright">
                      {doorERLWidth[0] && doorERLWidth[0]}
                      ,&nbsp;
                      {doorERLWidth[1] && doorERLWidth[1]}
                      ,&nbsp;
                      {doorERLWidth[2] && doorERLWidth[2]}
                    </p>
                  </div>
                )}
                {selectedERLPatioDoorOrientation[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Left Patio Door Orientation:</p>
                    <p className="modalrowright">
                      {selectedERLPatioDoorOrientation[0]}
                      ,&nbsp;
                      {selectedERLPatioDoorOrientation[1]}
                      ,&nbsp;
                      {selectedERLPatioDoorOrientation[2]}
                    </p>
                  </div>
                )}
                {selectedERLSwingDoorOrientation[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Left Swing Door Orientation:</p>
                    <p className="modalrowright">
                      {selectedERLSwingDoorOrientation[0]}
                      ,&nbsp;
                      {selectedERLSwingDoorOrientation[1]}
                      ,&nbsp;
                      {selectedERLSwingDoorOrientation[2]}
                    </p>
                  </div>
                )}
                {selectedERRPatioDoorOrientation[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Right Patio Door Orientation:
                    </p>
                    <p className="modalrowright">
                      {selectedERRPatioDoorOrientation[0]}
                      ,&nbsp;
                      {selectedERRPatioDoorOrientation[1]}
                      ,&nbsp;
                      {selectedERRPatioDoorOrientation[2]}
                    </p>
                  </div>
                )}
                {selectedERRSwingDoorOrientation[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Right Swing Door Orientation:
                    </p>
                    <p className="modalrowright">
                      {selectedERRSwingDoorOrientation[0]}
                      ,&nbsp;
                      {selectedERRSwingDoorOrientation[1]}
                      ,&nbsp;
                      {selectedERRSwingDoorOrientation[2]}
                    </p>
                  </div>
                )}
                {selectedERFPatioDoorOrientation[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Front Patio Door Orientation:
                    </p>
                    <p className="modalrowright">
                      {selectedERFPatioDoorOrientation[0]}
                      ,&nbsp;
                      {selectedERFPatioDoorOrientation[1]}
                      ,&nbsp;
                      {selectedERFPatioDoorOrientation[2]}
                      ,&nbsp;
                      {selectedERFPatioDoorOrientation[3]}
                      ,&nbsp;
                      {selectedERFPatioDoorOrientation[4]}
                      ,&nbsp;
                      {selectedERFPatioDoorOrientation[5]}
                      ,&nbsp;
                    </p>
                  </div>
                )}
                {selectedERFSwingDoorOrientation[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Front Swing Door Orientation:
                    </p>
                    <p className="modalrowright">
                      {selectedERFSwingDoorOrientation[0]}
                      ,&nbsp;
                      {selectedERFSwingDoorOrientation[1]}
                      ,&nbsp;
                      {selectedERFSwingDoorOrientation[2]}
                      ,&nbsp;
                      {selectedERFSwingDoorOrientation[3]}
                      ,&nbsp;
                      {selectedERFSwingDoorOrientation[4]}
                      ,&nbsp;
                      {selectedERFSwingDoorOrientation[5]}
                      ,&nbsp;
                    </p>
                  </div>
                )}
                {selectedDoorHardware && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Door Hardware:</p>
                    <p className="modalrowright">{selectedDoorHardware}</p>
                  </div>
                )}
                {selectedSwingDoorOrientation[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Door Orientation Wall {selectedCodes[0]}:
                    </p>
                    <p className="modalrowright">
                      {selectedSwingDoorOrientation[0]}
                    </p>
                  </div>
                )}
                {selectedSwingDoorOrientation[1] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Door Orientation Wall {selectedCodes[1]}:
                    </p>
                    <p className="modalrowright">
                      {selectedSwingDoorOrientation[1]}
                    </p>
                  </div>
                )}
                {selectedSwingDoorOrientation[2] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Door Orientation Wall {selectedCodes[2]}:
                    </p>
                    <p className="modalrowright">
                      {selectedSwingDoorOrientation[2]}
                    </p>
                  </div>
                )}
                {selectedPatioDoorOrientation[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Door Orientation Wall {selectedCodes[0]}:
                    </p>
                    <p className="modalrowright">
                      {selectedPatioDoorOrientation[0]}
                    </p>
                  </div>
                )}
                {selectedPatioDoorOrientation[1] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Door Orientation Wall {selectedCodes[1]}:
                    </p>
                    <p className="modalrowright">
                      {selectedPatioDoorOrientation[1]}
                    </p>
                  </div>
                )}
                {selectedPatioDoorOrientation[2] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Door Orientation Wall {selectedCodes[2]}:
                    </p>
                    <p className="modalrowright">
                      {selectedPatioDoorOrientation[2]}
                    </p>
                  </div>
                )}
                {personalInfo.lastName && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Last Name:</p>
                    <p className="modalrowright">{personalInfo.lastName}</p>
                  </div>
                )}
                {personalInfo.firstName && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">First Name:</p>
                    <p className="modalrowright">{personalInfo.firstName}</p>
                  </div>
                )}
                {personalInfo.address && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Address:</p>
                    <p className="modalrowright">{personalInfo.address}</p>
                  </div>
                )}
                {personalInfo.city && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">City:</p>
                    <p className="modalrowright">{personalInfo.city}</p>
                  </div>
                )}
                {personalInfo.stateProvince && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">State/Province:</p>
                    <p className="modalrowright">
                      {personalInfo.stateProvince}
                    </p>
                  </div>
                )}
                {personalInfo.zip && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Zip:</p>
                    <p className="modalrowright">{personalInfo.zip}</p>
                  </div>
                )}
                {personalInfo.purchaseOrder && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Purchase Order:</p>
                    <p className="modalrowright">
                      {personalInfo.purchaseOrder}
                    </p>
                  </div>
                )}
                {lengthFeet && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Feet of Length:</p>
                    <p className="modalrowright">{lengthFeet}</p>
                  </div>
                )}
                {lengthInches && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Inches of Length:</p>
                    <p className="modalrowright">{lengthInches}</p>
                  </div>
                )}
                {widthFeet && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Feet of Width:</p>
                    <p className="modalrowright">{widthFeet}</p>
                  </div>
                )}
                {widthInches && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Inches of Width:</p>
                    <p className="modalrowright">{widthInches}</p>
                  </div>
                )}
                {selectedShingle && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Shingle:</p>
                    <p className="modalrowright">{selectedShingle}</p>
                  </div>
                )}
                {selectedFanBeam && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Fan Beam:</p>
                    <p className="modalrowright">{selectedFanBeam}</p>
                  </div>
                )}
                {selectedRoofDensity && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Roof Density:</p>
                    <p className="modalrowright">{selectedRoofDensity}</p>
                  </div>
                )}
                {selectedSkyviewChoice && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Skyview:</p>
                    <p className="modalrowright">{selectedSkyviewChoice}</p>
                  </div>
                )}
                {selectedLEDLightChoice && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">LED Lights:</p>
                    <p className="modalrowright">{selectedLEDLightChoice}</p>
                  </div>
                )}
                {deckFlashingOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Deck Flashing Option:</p>
                    <p className="modalrowright">{deckFlashingOption}</p>
                  </div>
                )}
                {selectedElectricalOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Electrical Option:</p>
                    <p className="modalrowright">{selectedElectricalOption}</p>
                  </div>
                )}
                {selectedVertChannelOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Vert Channel Option:</p>
                    <p className="modalrowright">{selectedVertChannelOption}</p>
                  </div>
                )}
                {selectedWindowWallStyle && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Window Wall Style:</p>
                    <p className="modalrowright">{selectedWindowWallStyle}</p>
                  </div>
                )}
                {selectedBackWallPanels && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Back Wall Panels:</p>
                    <p className="modalrowright">{selectedBackWallPanels}</p>
                  </div>
                )}
                {sashOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Sash Option:</p>
                    <p className="modalrowright">{sashOption}</p>
                  </div>
                )}
                {selectedTopTransomOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Top Transom Option:</p>
                    <p className="modalrowright">{selectedTopTransomOption}</p>
                  </div>
                )}
                {selectedBotTransom && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Bottom Transom:</p>
                    <p className="modalrowright">{selectedBotTransom}</p>
                  </div>
                )}
                {selectedBotTransomOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Bottom Transom Option:</p>
                    <p className="modalrowright">{selectedBotTransomOption}</p>
                  </div>
                )}
                {selectedTopCustomReady && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Top Custom Ready:</p>
                    <p className="modalrowright">{selectedTopCustomReady}</p>
                  </div>
                )}
                {selectedCleatsOrBond && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Cleats or Bond Tape:</p>
                    <p className="modalrowright">{selectedCleatsOrBond}</p>
                  </div>
                )}
                {selectedRoomStyle && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Room Style:</p>
                    <p className="modalrowright">{selectedRoomStyle}</p>
                  </div>
                )}
                {frameOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Frame Option:</p>
                    <p className="modalrowright">{frameOption}</p>
                  </div>
                )}
                {fasciaTrimOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Fascia Trim Option:</p>
                    <p className="modalrowright">{fasciaTrimOption}</p>
                  </div>
                )}
                {exteriorKpOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Exterior KP Option:</p>
                    <p className="modalrowright">{exteriorKpOption}</p>
                  </div>
                )}
                {interiorKpOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Interior KP Option:</p>
                    <p className="modalrowright">{interiorKpOption}</p>
                  </div>
                )}
                {vinylOption && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Vinyl Color:</p>
                    <p className="modalrowright">{vinylOption}</p>
                  </div>
                )}
                {wallLengthsFeet[0] && wallLengthsInches[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Wall Length 1:</p>
                    <p className="modalrowright">
                      {wallLengthsFeet[0] + "'"}
                      {wallLengthsInches[0] + '"'}
                    </p>
                  </div>
                )}
                {wallLengthsFeet[1] && wallLengthsInches[1] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Wall Length 2:</p>
                    <p className="modalrowright">
                      {wallLengthsFeet[1] + "'"}
                      {wallLengthsInches[1] + '"'}
                    </p>
                  </div>
                )}
                {wallLengthsFeet[2] && wallLengthsInches[2] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">Wall Length 3:</p>
                    <p className="modalrowright">
                      {wallLengthsFeet[2] + "'"}
                      {wallLengthsInches[2] + '"'}
                    </p>
                  </div>
                )}
                {doorWidth[0] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Door Selection {selectedCodes[0]}:
                    </p>
                    <p className="modalrowright">{doorWidth[0]}</p>
                  </div>
                )}
                {doorWidth[1] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Door Selection {selectedCodes[1]}:
                    </p>
                    <p className="modalrowright">{doorWidth[1]}</p>
                  </div>
                )}
                {doorWidth[2] && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">
                      Door Selection {selectedCodes[2]}:
                    </p>
                    <p className="modalrowright">{doorWidth[2]}</p>
                  </div>
                )}
                {SSHinges && (
                  <div className="modalinfoleft">
                    <p className="modalrowleft">SS Hinges:</p>
                    <p className="modalrowright">{SSHinges}</p>
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "right",
                  margin: 0,
                  width: "50%",
                }}
              >
                {personalInfo.email && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Email:</p>
                    <p className="modalrowright">{personalInfo.email}</p>
                  </div>
                )}
                {personalInfo.sender && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Sender:</p>
                    <p className="modalrowright">{personalInfo.sender}</p>
                  </div>
                )}
                {selectedLocation && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Location:</p>
                    <p className="modalrowright">{selectedLocation}</p>
                  </div>
                )}
                {selectedSunRoomType && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Sunroom Type:</p>
                    <p className="modalrowright">{selectedSunRoomType}</p>
                  </div>
                )}
                {selectedPermitOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Drawing Package:</p>
                    <p className="modalrowright">{selectedPermitOption}</p>
                  </div>
                )}
                {selectedFoundationOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Foundation:</p>
                    <p className="modalrowright">{selectedFoundationOption}</p>
                  </div>
                )}
                {selectedExistingFoundationOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Existing Concrete:</p>
                    <p className="modalrowright">
                      {selectedExistingFoundationOption}
                    </p>
                  </div>
                )}
                {selectedNewFoundationOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">New Concrete:</p>
                    <p className="modalrowright">
                      {selectedNewFoundationOption}
                    </p>
                  </div>
                )}
                {selectedRoofLoadChange && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Roof Load:</p>
                    <p className="modalrowright">{selectedRoofLoadChange}</p>
                  </div>
                )}
                {selectedWindLoadChange && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Wind Load:</p>
                    <p className="modalrowright">{selectedWindLoadChange}</p>
                  </div>
                )}
                {selectedMountOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Mount Option:</p>
                    <p className="modalrowright">{selectedMountOption}</p>
                  </div>
                )}
                {selectedFasciaInches && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Soffit Depth:</p>
                    <p className="modalrowright">{selectedFasciaInches}</p>
                  </div>
                )}
                {stepDownInches && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Inches of Step Down:</p>
                    <p className="modalrowright">{stepDownInches}</p>
                  </div>
                )}
                {stepDownFeet && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Feet of Step Down:</p>
                    <p className="modalrowright">{stepDownFeet}</p>
                  </div>
                )}
                {gradeHeightInches && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Inches of Grade Height:</p>
                    <p className="modalrowright">{gradeHeightInches}</p>
                  </div>
                )}
                {gradeHeightFeet && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Feet of Grade Height:</p>
                    <p className="modalrowright">{gradeHeightFeet}</p>
                  </div>
                )}
                {selectedKneeWallOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Knee Wall Option:</p>
                    <p className="modalrowright">{selectedKneeWallOption}</p>
                  </div>
                )}
                {selectedKneeWallFeet && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Feet of Knee Wall:</p>
                    <p className="modalrowright">{selectedKneeWallFeet}</p>
                  </div>
                )}
                {selectedKneeWallInches && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Inches of Knee Wall:</p>
                    <p className="modalrowright">{selectedKneeWallInches}</p>
                  </div>
                )}
                {heightAtHouse.heightfeet && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Height at House Feet:</p>
                    <p className="modalrowright">{heightAtHouse.heightfeet}</p>
                  </div>
                )}
                {heightAtHouse.heightinches && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Height at House Inches:</p>
                    <p className="modalrowright">
                      {heightAtHouse.heightinches}
                    </p>
                  </div>
                )}
                {frontHeight.frontfeet && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Front Height Feet:</p>
                    <p className="modalrowright">{frontHeight.frontfeet}</p>
                  </div>
                )}
                {frontHeight.frontinches && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Front Height Inches:</p>
                    <p className="modalrowright">{frontHeight.frontinches}</p>
                  </div>
                )}
                {selectedValueSeries && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Value Series:</p>
                    <p className="modalrowright">{selectedValueSeries}</p>
                  </div>
                )}
                {selectedRoofPanelColorOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Roof Panel Color:</p>
                    <p className="modalrowright">
                      {selectedRoofPanelColorOption}
                    </p>
                  </div>
                )}
                {selectedACLeftOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Left Projection AC:</p>
                    <p className="modalrowright">{selectedACLeftOption}</p>
                  </div>
                )}
                {selectedACCenterOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Front Wall AC:</p>
                    <p className="modalrowright">{selectedACCenterOption}</p>
                  </div>
                )}
                {selectedACRightOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Right Projection AC:</p>
                    <p className="modalrowright">{selectedACRightOption}</p>
                  </div>
                )}
                {selectedSkinType && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Skin Type:</p>
                    <p className="modalrowright">{selectedSkinType}</p>
                  </div>
                )}
                {selectedPavilionLayoutOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Structure Layout:</p>
                    {Number(selectedPavilionLayoutOption) === 3 && (
                      <p className="modalrowright">
                        {selectedPavilionLayoutOption} Wall
                      </p>
                    )}
                    {Number(selectedPavilionLayoutOption) !== 3 && (
                      <p className="modalrowright">
                        {selectedPavilionLayoutOption} Walls
                      </p>
                    )}
                  </div>
                )}
                {selectedRailSideOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Wall Side:</p>
                    <p className="modalrowright">{selectedRailSideOption}</p>
                  </div>
                )}
                {selectedRailAmountOption &&
                  selectedRailAmountOption.feet &&
                  selectedRailAmountOption.inches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Rail Amount:</p>
                      <p className="modalrowright">
                        {selectedRailAmountOption.feet} Feet and
                        {selectedRailAmountOption.inches} Inches
                      </p>
                    </div>
                  )}
                {initialSteelPiers && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Steel Piers:</p>
                    <p className="modalrowright">{initialSteelPiers}</p>
                  </div>
                )}
                {deckWidthFeetOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Deck Width Feet:</p>
                    <p className="modalrowright">{deckWidthFeetOption}</p>
                  </div>
                )}
                {deckWidthInchesOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Deck Width Inches:</p>
                    <p className="modalrowright">{deckWidthInchesOption}</p>
                  </div>
                )}
                {deckLengthFeetOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Deck Length Feet:</p>
                    <p className="modalrowright">{deckLengthFeetOption}</p>
                  </div>
                )}
                {deckLengthInchesOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Deck Length Inches:</p>
                    <p className="modalrowright">{deckLengthInchesOption}</p>
                  </div>
                )}
                {chairRailWidthFeet && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Chair Rail Feet:</p>
                    <p className="modalrowright">{chairRailWidthFeet}</p>
                  </div>
                )}
                {chairRailWidthInches && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Chair Rail Inches:</p>
                    <p className="modalrowright">{chairRailWidthInches}</p>
                  </div>
                )}
                {bottomKPWidthFeet && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Bottom KP Feet:</p>
                    <p className="modalrowright">{bottomKPWidthFeet}</p>
                  </div>
                )}
                {bottomKPWidthInches && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Bottom KP Inches:</p>
                    <p className="modalrowright">{bottomKPWidthInches}</p>
                  </div>
                )}
                {selectedTEMOCornersOption && (
                  <div className="modalinforight">
                    <p className="modalrowleft">TEMO Corners:</p>
                    <p className="modalrowright">{selectedTEMOCornersOption}</p>
                  </div>
                )}
                {glassRows !== 0 && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Glass Rows:</p>
                    <p className="modalrowright">{glassRows}</p>
                  </div>
                )}
                {bays && (
                  <div className="modalinforight">
                    <p className="modalrowleft">Bays:</p>
                    <p className="modalrowright">{bays}</p>
                  </div>
                )}
              </div>
            </div>
            <div
              style={{
                height: "fit-content",
                marginTop: "1%",
                marginBottom: "5%",
              }}
            >
              <div>
                {Array.from({ length: 5 }).map((_, index) => (
                  <textarea
                    key={index}
                    value={additionalInfo[index] || ""}
                    onChange={(e) =>
                      handleAdditionalInfoChange(index, e.target.value)
                    }
                    placeholder={`Special Instructions ${index + 1}`}
                    rows="1"
                    maxLength="48"
                    style={{ width: "94%", margin: "-.4%" }}
                  />
                ))}
              </div>
              <div>
                <b>Please Email Attachments To: </b>
                <br></br>
                <b>Steve Salter:&nbsp;</b>
                <a href="mailto:ssalter@temoinc.com">ssalter@temoinc.com</a>
                &nbsp;&nbsp;
                <b>Phil Taravella:&nbsp;</b>
                <a href="mailto:ptaravella@temoinc.com">
                  ptaravella@temoinc.com
                </a>
                <br></br>
                <br></br>
              </div>
              <button
                className="showpdfbuttons"
                style={{
                  position: "fixed",
                  bottom: "9%",
                  left: "40.9%",
                  width: "fit-content",
                  height: "fit-content",
                }}
                onClick={() => {
                  setSubmitIsLoading(false);
                  closeModal();
                }}
              >
                No
              </button>
              {formattedMissingFields.length === 0 && (
                <button
                  className="showpdfbuttons"
                  style={{
                    position: "fixed",
                    bottom: "9%",
                    left: "56%",
                    width: "fit-content",
                    height: "fit-content",
                  }}
                  onClick={handleSubmit}
                >
                  Yes
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SunroomSubmitButton.propTypes = {
  closeModal: PropTypes.func,
  openModal: PropTypes.func,
  getAccessTokenSilently: PropTypes.func,
  selectedPermitOption: PropTypes.string,
  selectedFoundationOptionc: PropTypes.string,
  selectedKneeWallOption: PropTypes.string,
  selectedMountOption: PropTypes.string,
  personalInfo: PropTypes.object,
  selectedExistingFoundationOption: PropTypes.string,
  selectedNewFoundationOption: PropTypes.string,
  gradeHeightFeet: PropTypes.string,
  gradeHeightInches: PropTypes.string,
  stepDownInches: PropTypes.string,
  stepDownFeet: PropTypes.string,
  selectedWindLoadChange: PropTypes.string,
  selectedRoofLoadChange: PropTypes.string,
  widthFeet: PropTypes.string,
  widthInches: PropTypes.string,
  lengthFeet: PropTypes.string,
  lengthInches: PropTypes.string,
  selectedKneeWallFeet: PropTypes.string,
  selectedKneeWallInches: PropTypes.string,
  selectedFasciaInches: PropTypes.string,
  onRedBorderCheck: PropTypes.func,
  selectedShingle: PropTypes.string,
  selectedFanBeam: PropTypes.string,
  selectedRoofDensity: PropTypes.string,
  heightAtHouse: PropTypes.object,
  frontHeight: PropTypes.object,
  selectedSkyviewChoice: PropTypes.string,
  selectedValueSeries: PropTypes.string,
  selectedLEDLightChoice: PropTypes.string,
  deckFlashingOption: PropTypes.string,
  selectedElectricalOption: PropTypes.string,
  selectedVertChannelOption: PropTypes.string,
  selectedWindowWallStyle: PropTypes.string,
  selectedRoomStyle: PropTypes.string,
  selectedBotTransom: PropTypes.string,
  selectedBackWallPanels: PropTypes.string,
  frameOption: PropTypes.string,
  fasciaTrimOption: PropTypes.string,
  exteriorKpOption: PropTypes.string,
  interiorKpOption: PropTypes.string,
  selectedBotTransomOption: PropTypes.string,
  selectedTopTransomOption: PropTypes.string,
  selectedCleatsOrBond: PropTypes.string,
  selectedRoofPanelColorOption: PropTypes.string,
  selectedACLeftOption: PropTypes.string,
  selectedACCenterOption: PropTypes.string,
  selectedACRightOption: PropTypes.string,
  selectedFoundationOption: PropTypes.string,
  selectedCodes: PropTypes.array,
  selectedSwingDoorOrientation: PropTypes.array,
  selectedPatioDoorOrientation: PropTypes.array,
  selectedDoorHardware: PropTypes.string,
  doorOption: PropTypes.array,
  doorWidth: PropTypes.array,
  wallLengthsFeet: PropTypes.array,
  wallLengthsInches: PropTypes.array,
  SSHinges: PropTypes.string,
  selectedSkinType: PropTypes.string,
  selectedPavilionLayoutOption: PropTypes.string,
  selectedRailSideOption: PropTypes.string,
  selectedRailAmountOption: PropTypes.object,
  selectedSunRoomType: PropTypes.string,
  initialSteelPiers: PropTypes.string,
  deckLengthFeetOption: PropTypes.string,
  deckLengthInchesOption: PropTypes.string,
  deckWidthFeetOption: PropTypes.string,
  deckWidthInchesOption: PropTypes.string,
  isValidEmail: PropTypes.bool,
  locations: PropTypes.object,
  selectedLocation: PropTypes.string,
  selectedBreezeWay: PropTypes.string,
  selectedTopCustomReady: PropTypes.string,
  wallERLLengthsFeet: PropTypes.array,
  wallERLLengthsInches: PropTypes.array,
  wallERRLengthsFeet: PropTypes.array,
  wallERRLengthsInches: PropTypes.array,
  wallERFLengthsFeet: PropTypes.array,
  wallERFLengthsInches: PropTypes.array,
  doorERFOption: PropTypes.array,
  doorERROption: PropTypes.array,
  doorERLOption: PropTypes.array,
  doorERFWidth: PropTypes.array,
  doorERRWidth: PropTypes.array,
  doorERLWidth: PropTypes.array,
  selectedERLPatioDoorOrientation: PropTypes.array,
  selectedERLSwingDoorOrientation: PropTypes.array,
  selectedERRPatioDoorOrientation: PropTypes.array,
  selectedERRSwingDoorOrientation: PropTypes.array,
  selectedERFPatioDoorOrientation: PropTypes.array,
  selectedERFSwingDoorOrientation: PropTypes.array,
  selectedERLCodes: PropTypes.array,
  selectedERRCodes: PropTypes.array,
  selectedERFCodes: PropTypes.array,
  selectedAngleOption: PropTypes.number,
  selectedAngled: PropTypes.string,
  wallStraightLengthsInches: PropTypes.array,
  wallStraightLengthsFeet: PropTypes.array,
  doorStraightOption: PropTypes.array,
  doorStraightWidth: PropTypes.array,
  selectedStraightSwingDoorOrientation: PropTypes.array,
  selectedStraightPatioDoorOrientation: PropTypes.array,
  selectedStraightCodes: PropTypes.array,
  rightAngleFeet: PropTypes.string,
  rightAngleInches: PropTypes.string,
  leftAngleFeet: PropTypes.string,
  leftAngleInches: PropTypes.string,
  sashOption: PropTypes.string,
  isScreenRoom: PropTypes.bool,
  selectedERFLocations: PropTypes.array,
  selectedERRLocations: PropTypes.array,
  selectedERLLocations: PropTypes.array,
  selectedLocations: PropTypes.array,
  chairRailWidthFeet: PropTypes.string,
  chairRailWidthInches: PropTypes.string,
  bottomKPWidthFeet: PropTypes.string,
  bottomKPWidthInches: PropTypes.string,
  selectedTEMOCornersOption: PropTypes.string,
  screenDoorWidth: PropTypes.string,
  vinylOption: PropTypes.string,
  glassRows: PropTypes.number,
  bays: PropTypes.string,
  currentCADImagesFiles: PropTypes.array,
  setCurrentCADImagesFiles: PropTypes.func,
  drawingImages: PropTypes.array,
  setDrawingImages: PropTypes.func,
};

export default SunroomSubmitButton;
