import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import InfoModal from "./InfoModal";
// import DrawingOverlaySelection from "../OrderingSystemComponents/DrawingOverlay/DrawingOverlaySelection";
// import CrossSection from "./P024-A305-196-Cross-Section.jpg";
// import PlanView from "./P024-A305-196-PlanView.jpg";
// import Elevation from "./P024-A305-196-Elevation.jpg";
// import RoofAttach from "./P024-A305-196-RoofAttach.jpg";

const Callback = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [userCompany, setUserCompany] = useState(null);
  const { isLoading } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // const currentCADImagesFiles = "test";
  // const [drawingImages, setDrawingImages] = useState([
  //   CrossSection,
  //   PlanView,
  //   Elevation,
  //   RoofAttach,
  // ]);
  // const [isDrawingOverlayOpen, setIsDrawingOverlayOpen] = useState(false);
  // const sentCorrectly = true;

  useEffect(() => {
    const fetchUserCompany = async () => {
      if (userCompany) return;
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });

      fetch("/api/DealerUserIDName/DealerName", {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => setUserCompany(data.dealerName))
        .catch((error) => {
          console.error("Error:", error);
        });
    };

    fetchUserCompany();
  }, [getAccessTokenSilently, userCompany]);

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!userCompany) {
    return (
      <>
        <div>Loading...</div>
        <button
          style={{ position: "absolute", top: "20%", right: "1%" }}
          className="navbutton"
          onClick={() => setIsModalOpen(true)}
        >
          How To
        </button>
        <InfoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        {/* <DrawingOverlaySelection
          currentCADImagesFiles={currentCADImagesFiles}
          drawingImages={drawingImages}
          setDrawingImages={setDrawingImages}
          setIsDrawingOverlayOpen={setIsDrawingOverlayOpen}
          sentCorrectly={sentCorrectly}
          isDrawingOverlayOpen={isDrawingOverlayOpen}
        /> */}
      </>
    );
  }

  return (
    <>
      <div className="callback">
        WELCOME!
        <br></br>
        {userCompany}
      </div>
      <button
        style={{ position: "absolute", top: "20%", right: "1%" }}
        className="navbutton"
        onClick={() => setIsModalOpen(true)}
      >
        How To
      </button>
      <InfoModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
};

export default Callback;
