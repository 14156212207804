import React, { useState, useEffect, useCallback } from "react";
import MissingFieldsBox from "../UniversalComponents/SubmitMessages/cantsubmitmessage";
import ErrorMessage from "../UniversalComponents/SubmitMessages/ErrorMessage";
import { useAuth0 } from "@auth0/auth0-react";
import PropTypes from "prop-types";
import HoldErrorMessage from "../UniversalComponents/SubmitMessages/HoldErrorMessage";
import RepErrorMessage from "../UniversalComponents/SubmitMessages/RepErrorMessage";
import SuccessMessage from "../UniversalComponents/SubmitMessages/SuccessMessage";
import PatioLockMessage from "../UniversalComponents/SubmitMessages/PatioCoverLock";
import SimpleBar from "simplebar-react";
// import DrawingOverlaySelection from "../DrawingOverlay/DrawingOverlaySelection";
import "simplebar/dist/simplebar.min.css";

const SubmitButton = ({
  selectedTrimOption,
  selectedOperableOption,
  selectedBiHexOption,
  selectedExteriorOption,
  selectedPostStyleOption,
  selectedActuatorOption,
  selectedAttachedOption,
  selectedPermitOption,
  selectedFoundationOption,
  selectedKneeWallOption,
  selectedMountOption,
  personalInfo,
  selectedExistingFoundationOption,
  selectedNewFoundationOption,
  gradeHeightInches,
  gradeHeightFeet,
  stepDownFeet,
  stepDownInches,
  selectedRoofLoadChange,
  selectedWindLoadChange,
  widthFeet,
  widthInches,
  lengthFeet,
  lengthInches,
  selectedPostHeight,
  selectedKneeWallInches,
  selectedKneeWallFeet,
  selectedFasciaInches,
  onRedBorderCheck,
  selectedBeamOption,
  selectedRafterColor,
  selectedGutterOption,
  selectedLength,
  selectedWidth,
  initialSteelPiers,
  isValidEmail,
  selectedLocation,
  locations,
  // setCurrentCADImagesFiles,
  // currentCADImagesFiles,
  // drawingImages,
  // setDrawingImages,
}) => {
  const [formattedMissingFields, setFormattedMissingFields] = useState([""]);
  const [missingFields, setMissingFields] = useState([""]);
  const [showMissingFields, setShowMissingFields] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [endMessage, setEndMessage] = useState("");
  const [showEndMessage, setShowEndMessage] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState([""]);
  const [sentCorrectly, setSentCorrectly] = useState(false);
  const [motorCityPergolaSize, setMotorCityPergolaSize] = useState("");
  const [submitIsLoading, setSubmitIsLoading] = useState(false);
  // const [isDrawingOverlayOpen, setIsDrawingOverlayOpen] = useState(false);

  const { getAccessTokenSilently } = useAuth0();

  const handleAdditionalInfoChange = (index, value) => {
    const newAdditionalInfo = [...additionalInfo];
    newAdditionalInfo[index] =
      value.length > 48 ? value.substring(0, 48) : value;
    setAdditionalInfo(newAdditionalInfo);
  };

  const openModal = (e) => {
    e.preventDefault();
    setShowMissingFields(true);
    onRedBorderCheck(missingFields);
    if (formattedMissingFields.length === 0) {
      setShowModal(true);
    }
  };

  const closeModal = (e) => {
    setShowModal(false);
  };

  const checkMissingFields = useCallback(() => {
    const newFormattedMissingFields = [];
    const missingFields = [];

    if (selectedOperableOption === "MOTOR CITY") {
      if (
        (selectedLength === "10" &&
          (selectedWidth === "10" || selectedWidth === "12")) ||
        (selectedLength === "12" &&
          (selectedWidth === "10" || selectedWidth === "12"))
      ) {
        if (!initialSteelPiers) {
          newFormattedMissingFields.push("Steel Piers");
          missingFields.push("steel piers");
        }
      }
      if (!selectedTrimOption) {
        newFormattedMissingFields.push("Trim Color");
        missingFields.push("Trim Color");
      }
      if (!selectedRafterColor) {
        newFormattedMissingFields.push("Rafter Color");
        missingFields.push("Rafter Color");
      }
      if (!selectedLength) {
        newFormattedMissingFields.push("Length");
        missingFields.push("length");
      }
      if (!selectedWidth) {
        newFormattedMissingFields.push("Width");
        missingFields.push("width");
      }
      if (!selectedWindLoadChange) {
        newFormattedMissingFields.push("Wind Load");
        missingFields.push("WindLoad");
      }
      if (!selectedRoofLoadChange) {
        newFormattedMissingFields.push("Roof Load");
        missingFields.push("RoofLoad");
      }

      if (!selectedPermitOption) {
        newFormattedMissingFields.push("Drawing Package");
        missingFields.push("permit package option");
      }

      if (selectedPermitOption === "YES") {
        if (!selectedFoundationOption) {
          newFormattedMissingFields.push("Foundation Option");
          missingFields.push("foundation option");
        }
        if (selectedFoundationOption === "newconcrete") {
          if (!selectedNewFoundationOption) {
            newFormattedMissingFields.push("New Concrete Option");
            missingFields.push("Pick a new concrete option");
          }
        }
        if (selectedFoundationOption === "existingconcrete") {
          if (!selectedExistingFoundationOption) {
            newFormattedMissingFields.push("Existing Concrete Option");
            missingFields.push("Pick an existing slab option");
          }
        }

        if (
          selectedFoundationOption === "existingwood" ||
          selectedFoundationOption === "nontemowood"
        ) {
          if (!gradeHeightInches) {
            newFormattedMissingFields.push("Grade Height Inches");
            missingFields.push("Please input inches grade height");
          }
          if (!gradeHeightFeet) {
            newFormattedMissingFields.push("Grade Height Feet");
            missingFields.push("Please input feet grade height");
          }
        }
      }
      for (const key in personalInfo) {
        if (key === "firstName" && personalInfo[key] === "") {
          continue;
        }
        if (key === "purchaseOrder" && personalInfo[key] === "") {
          continue;
        }
        if (!personalInfo[key]) {
          missingFields.push(key);
          if (key === "lastName") {
            newFormattedMissingFields.push("Last Name");
          }
          if (key === "sender") {
            newFormattedMissingFields.push("Sender");
          }
          if (key === "email") {
            newFormattedMissingFields.push("Email");
          }
          if (key === "address") {
            newFormattedMissingFields.push("Address");
          }
          if (key === "city") {
            newFormattedMissingFields.push("City");
          }
          if (key === "stateProvince") {
            newFormattedMissingFields.push("State/Province");
          }
          if (key === "zip") {
            newFormattedMissingFields.push("Zip Code");
          }
        }
      }
      if (personalInfo["email"] && !isValidEmail) {
        newFormattedMissingFields.push("Invalid Email");
        missingFields.push("email");
      }
      if (locations && Object.keys(locations).length > 0) {
        if (!selectedLocation) {
          newFormattedMissingFields.push("Location");
          missingFields.push("location");
        }
      }
      if (selectedLength && selectedWidth) {
        setMotorCityPergolaSize(selectedLength + "x" + selectedWidth);
      }
    } else if (selectedOperableOption !== "MOTOR CITY") {
      if (selectedOperableOption === "PERGOLA OPERABLE") {
        if (!selectedGutterOption) {
          newFormattedMissingFields.push("Gutter Option");
          missingFields.push("Gutter Option");
        }
      }

      if (!selectedTrimOption) {
        newFormattedMissingFields.push("Trim Color");
        missingFields.push("Trim Color");
      }
      if (!selectedRafterColor) {
        newFormattedMissingFields.push("Rafter Color");
        missingFields.push("Rafter Color");
      }
      if (!selectedOperableOption) {
        newFormattedMissingFields.push("Pergola Type");
        missingFields.push("Operable or Fixed");
      }
      if (
        selectedOperableOption === "PERGOLA OPERABLE" &&
        !selectedActuatorOption
      ) {
        newFormattedMissingFields.push("Cable Option");
        missingFields.push("actuator cable option");
      }
      if (!selectedBiHexOption) {
        newFormattedMissingFields.push("Bi-Hex Tool");
        missingFields.push("Bi-Hex tool");
      }
      if (!selectedExteriorOption) {
        newFormattedMissingFields.push("Exterior Style");
        missingFields.push("Exterior Style");
      }

      if (selectedPostStyleOption !== "NO COLUMNS OR BEAMS NEEDED") {
        if (!selectedBeamOption) {
          newFormattedMissingFields.push("Structural Beam");
          missingFields.push("Structural Beam");
        }
      }

      if (!selectedPostStyleOption) {
        newFormattedMissingFields.push("Post Style");
        missingFields.push("Post Style");
      } else if (
        selectedPostStyleOption === "ROMA" ||
        selectedPostStyleOption === "FLORENTINE"
      ) {
        if (!selectedPostHeight) {
          newFormattedMissingFields.push("Post Height");
          missingFields.push("post height");
        }
      }
      if (!selectedAttachedOption) {
        newFormattedMissingFields.push("Free-Standing or Attached");
        missingFields.push("Free-Standing or Attached");
      }
      if (!selectedPermitOption) {
        newFormattedMissingFields.push("Drawing Package");
        missingFields.push("permit package option");
      }
      if (!lengthFeet || lengthFeet < 4) {
        newFormattedMissingFields.push("Length Feet");
        missingFields.push("length feet");
      }
      if (!widthFeet || widthFeet < 4) {
        newFormattedMissingFields.push("Width Feet");
        missingFields.push("width feet");
      }
      if (!lengthInches) {
        newFormattedMissingFields.push("Overall Length Inches");
        missingFields.push("length inches");
      }
      if (!widthInches) {
        newFormattedMissingFields.push("Overall Width Inches");
        missingFields.push("width inches");
      }

      if (!selectedWindLoadChange) {
        newFormattedMissingFields.push("Wind Load");
        missingFields.push("WindLoad");
      }

      if (!selectedRoofLoadChange) {
        newFormattedMissingFields.push("Roof Load");
        missingFields.push("RoofLoad");
      }

      if (selectedPermitOption === "YES") {
        if (!selectedFoundationOption) {
          newFormattedMissingFields.push("Foundation Option");
          missingFields.push("foundation option");
        }
        if (selectedFoundationOption === "newconcrete") {
          if (!selectedNewFoundationOption) {
            newFormattedMissingFields.push("New Concrete Option");
            missingFields.push("Pick a new concrete option");
          }
        }
        if (selectedFoundationOption === "existingconcrete") {
          if (!selectedExistingFoundationOption) {
            newFormattedMissingFields.push("Existing Concrete Option");
            missingFields.push("Pick an existing slab option");
          }
        }

        if (
          selectedFoundationOption === "existingwood" ||
          selectedFoundationOption === "nontemowood"
        ) {
          if (!gradeHeightInches) {
            newFormattedMissingFields.push("Grade Height Inches");
            missingFields.push("Please input inches grade height");
          }
          if (!gradeHeightFeet) {
            newFormattedMissingFields.push("Grade Height Feet");
            missingFields.push("Please input feet grade height");
          }
        }
        if (!selectedKneeWallOption) {
          newFormattedMissingFields.push("Knee Wall Option");
          missingFields.push("knee wall option");
        } else if (selectedKneeWallOption === "yes") {
          if (!selectedKneeWallFeet) {
            newFormattedMissingFields.push("Knee Wall Feet");
            missingFields.push("Select Knee Wall Feet");
          }
          if (!selectedKneeWallInches) {
            newFormattedMissingFields.push("Knee Wall Inches");
            missingFields.push("Select Knee Wall Inches");
          }
        }
      }
      if (
        selectedPermitOption === "YES" &&
        selectedAttachedOption === "attached"
      ) {
        if (!selectedMountOption) {
          newFormattedMissingFields.push("Mount Option");
          missingFields.push("pick a mount");
        } else if (selectedMountOption === "A") {
          if (!selectedFasciaInches) {
            newFormattedMissingFields.push("Soffit Depth");
            missingFields.push("fascia inches");
          }
        }
        if (!stepDownFeet) {
          newFormattedMissingFields.push("Step Down Feet");
          missingFields.push("stepdown feet");
        }

        if (!stepDownInches) {
          newFormattedMissingFields.push("Step Down Inches");
          missingFields.push("stepdown inches");
        }
      }
      for (const key in personalInfo) {
        if (key === "firstName" && personalInfo[key] === "") {
          continue;
        }
        if (key === "purchaseOrder" && personalInfo[key] === "") {
          continue;
        }
        if (!personalInfo[key]) {
          missingFields.push(key);
          if (key === "lastName") {
            newFormattedMissingFields.push("Last Name");
          }
          if (key === "sender") {
            newFormattedMissingFields.push("Sender");
          }
          if (key === "email") {
            newFormattedMissingFields.push("Email");
          }
          if (key === "address") {
            newFormattedMissingFields.push("Address");
          }
          if (key === "city") {
            newFormattedMissingFields.push("City");
          }
          if (key === "stateProvince") {
            newFormattedMissingFields.push("State/Province");
          }
          if (key === "zip") {
            newFormattedMissingFields.push("Zip Code");
          }
        }
      }
      if (personalInfo["email"] && !isValidEmail) {
        newFormattedMissingFields.push("Invalid Email");
        missingFields.push("email");
      }
      if (locations && Object.keys(locations).length > 0) {
        if (!selectedLocation) {
          newFormattedMissingFields.push("Location");
          missingFields.push("location");
        }
      }
    }
    setMissingFields(missingFields);
    setFormattedMissingFields(newFormattedMissingFields);
  }, [
    gradeHeightFeet,
    gradeHeightInches,
    lengthFeet,
    lengthInches,
    personalInfo,
    selectedActuatorOption,
    selectedAttachedOption,
    selectedBeamOption,
    selectedBiHexOption,
    selectedExistingFoundationOption,
    selectedExteriorOption,
    selectedFasciaInches,
    selectedFoundationOption,
    selectedKneeWallFeet,
    selectedKneeWallInches,
    selectedKneeWallOption,
    selectedMountOption,
    selectedNewFoundationOption,
    selectedOperableOption,
    selectedPermitOption,
    selectedPostHeight,
    selectedPostStyleOption,
    selectedRoofLoadChange,
    selectedTrimOption,
    selectedWindLoadChange,
    stepDownFeet,
    stepDownInches,
    widthFeet,
    widthInches,
    selectedRafterColor,
    selectedGutterOption,
    selectedLength,
    selectedWidth,
    initialSteelPiers,
    isValidEmail,
    selectedLocation,
    locations,
  ]);

  useEffect(() => {
    checkMissingFields();
  }, [
    checkMissingFields,
    selectedTrimOption,
    selectedOperableOption,
    selectedBiHexOption,
    selectedExteriorOption,
    selectedPostStyleOption,
    selectedActuatorOption,
    selectedAttachedOption,
    selectedPermitOption,
    selectedFoundationOption,
    selectedKneeWallOption,
    selectedMountOption,
    personalInfo,
    selectedExistingFoundationOption,
    selectedNewFoundationOption,
    selectedRoofLoadChange,
    selectedWindLoadChange,
    widthFeet,
    widthInches,
    lengthFeet,
    lengthInches,
    stepDownInches,
    stepDownFeet,
    selectedPostHeight,
    selectedKneeWallFeet,
    selectedKneeWallInches,
    selectedFasciaInches,
    gradeHeightInches,
    gradeHeightFeet,
    selectedBeamOption,
    selectedRafterColor,
    selectedGutterOption,
    selectedLength,
    selectedWidth,
    initialSteelPiers,
    selectedLocation,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitIsLoading(true);
    const newMissingFields = [];
    if (selectedOperableOption === "MOTOR CITY") {
      if (
        (selectedLength === "10" &&
          (selectedWidth === "10" || selectedWidth === "12")) ||
        (selectedLength === "12" &&
          (selectedWidth === "10" || selectedWidth === "12"))
      ) {
        if (!initialSteelPiers) {
          newMissingFields.push("steel piers");
        }
      }
      if (!selectedTrimOption) {
        newMissingFields.push("Trim Color");
      }
      if (!selectedRafterColor) {
        newMissingFields.push("Rafter Color");
      }
      if (!selectedLength) {
        newMissingFields.push("length");
      }
      if (!selectedWidth) {
        newMissingFields.push("width");
      }
      if (!selectedWindLoadChange) {
        newMissingFields.push("WindLoad");
      }
      if (!selectedRoofLoadChange) {
        newMissingFields.push("RoofLoad");
      }

      if (!selectedPermitOption) {
        newMissingFields.push("permit package option");
      }

      if (selectedPermitOption === "YES") {
        if (!selectedFoundationOption) {
          newMissingFields.push("foundation option");
        }
        if (selectedFoundationOption === "newconcrete") {
          if (!selectedNewFoundationOption) {
            newMissingFields.push("Pick a new concrete option");
          }
        }
        if (selectedFoundationOption === "existingconcrete") {
          if (!selectedExistingFoundationOption) {
            newMissingFields.push("Pick an existing slab option");
          }
        }

        if (
          selectedFoundationOption === "existingwood" ||
          selectedFoundationOption === "nontemowood"
        ) {
          if (!gradeHeightInches) {
            newMissingFields.push("Please input inches grade height");
          }
          if (!gradeHeightFeet) {
            newMissingFields.push("Please input feet grade height");
          }
        }
        for (const key in personalInfo) {
          if (key === "firstName" && personalInfo[key] === "") {
            continue;
          }
          if (key === "purchaseOrder" && personalInfo[key] === "") {
            continue;
          }
          if (!personalInfo[key]) {
            newMissingFields.push(key);
          }
        }
      }
      selectedBiHexOption = "";
      selectedExteriorOption = "";
      selectedPostStyleOption = "";
      selectedActuatorOption = "";
      selectedAttachedOption = "";
      selectedKneeWallOption = "";
      widthFeet = "";
      widthInches = "";
      lengthFeet = "";
      lengthInches = "";
      stepDownInches = "";
      stepDownFeet = "";
      selectedPostHeight = "";
      selectedKneeWallFeet = "";
      selectedKneeWallInches = "";
      selectedFasciaInches = "";
      selectedBeamOption = "";
      selectedGutterOption = "";
      if (selectedLength && selectedWidth) {
        setMotorCityPergolaSize(selectedLength + "x" + selectedWidth);
      }
    } else if (selectedOperableOption !== "MOTOR CITY") {
      initialSteelPiers = "";
      if (selectedOperableOption === "PERGOLA OPERABLE") {
        if (!selectedGutterOption) {
          newMissingFields.push("Gutter Option");
        }
      }
      if (selectedOperableOption === "PERGOLA FIXED") {
        selectedGutterOption = "NO";
      }

      if (!selectedRafterColor) {
        newMissingFields.push("Rafter Color");
      }
      if (!selectedTrimOption) {
        newMissingFields.push("Trim Color");
      }
      if (!selectedOperableOption) {
        newMissingFields.push("Operable or Fixed");
      }
      if (
        selectedOperableOption === "PERGOLA OPERABLE" &&
        !selectedActuatorOption
      ) {
        newMissingFields.push("actuator cable option");
      }
      if (!selectedBiHexOption) {
        newMissingFields.push("Bi-Hex tool");
      }
      if (!selectedExteriorOption) {
        newMissingFields.push("Exterior Style");
      }
      if (selectedPostStyleOption !== "NO COLUMNS OR BEAMS NEEDED") {
        if (!selectedBeamOption) {
          newMissingFields.push("Structural Beam");
        }
      }
      if (selectedPostStyleOption === "NO COLUMNS OR BEAMS NEEDED") {
        selectedBeamOption = "";
      }
      if (!selectedPostStyleOption) {
        newMissingFields.push("Post Style");
      } else if (
        selectedPostStyleOption === "ROMA" ||
        selectedPostStyleOption === "FLORENTINE"
      ) {
        if (!selectedPostHeight) {
          newMissingFields.push("post height");
        }
      } else if (
        selectedPostStyleOption === "NO COLUMNS NEEDED" ||
        selectedPostStyleOption === "NO COLUMNS OR BEAMS NEEDED"
      ) {
        selectedPostHeight = "";
      }
      if (!selectedAttachedOption) {
        newMissingFields.push("Free-Standing or Attached");
      } else if (selectedAttachedOption === "FREE-STANDING") {
        selectedMountOption = "";
        stepDownFeet = "";
        stepDownInches = "";
      }
      if (!selectedPermitOption) {
        newMissingFields.push("permit package option");
      }
      if (!lengthFeet || lengthFeet < 4) {
        newMissingFields.push("length feet");
      }
      if (!widthFeet || widthFeet < 4) {
        newMissingFields.push("width feet");
      }
      if (!lengthInches) {
        newMissingFields.push("length inches");
      }
      if (!widthInches) {
        newMissingFields.push("width inches");
      }

      if (!selectedWindLoadChange) {
        newMissingFields.push("WindLoad");
      }

      if (!selectedRoofLoadChange) {
        newMissingFields.push("RoofLoad");
      }

      if (selectedPermitOption === "YES") {
        if (!selectedFoundationOption) {
          newMissingFields.push("foundation option");
        }
        if (selectedFoundationOption === "newconcrete") {
          if (!selectedNewFoundationOption) {
            newMissingFields.push("Pick a new concrete option");
          }
        }
        if (selectedFoundationOption === "existingconcrete") {
          if (!selectedExistingFoundationOption) {
            newMissingFields.push("Pick an existing slab option");
          }
        }
        if (
          selectedFoundationOption === "existingwood" ||
          selectedFoundationOption === "nontemowood"
        ) {
          if (!gradeHeightInches) {
            newMissingFields.push("Please input inches grade height");
          }
          if (!gradeHeightFeet) {
            newMissingFields.push("Please input feet grade height");
          }
        }
        if (!selectedKneeWallOption) {
          newMissingFields.push("knee wall option");
        } else if (selectedKneeWallOption === "yes") {
          if (!selectedKneeWallFeet) {
            newMissingFields.push("Select Knee Wall Feet");
          }
          if (!selectedKneeWallInches) {
            newMissingFields.push("Select Knee Wall Inches");
          }
        } else if (selectedKneeWallOption === "no") {
          selectedKneeWallInches = "";
          selectedKneeWallFeet = "";
        }
      } else if (selectedPermitOption === "NO") {
        selectedKneeWallFeet = "";
        selectedKneeWallInches = "";
        gradeHeightInches = "";
        gradeHeightFeet = "";
        selectedMountOption = "";
        stepDownFeet = "";
        stepDownInches = "";
      }
      if (
        selectedPermitOption === "YES" &&
        selectedAttachedOption === "attached"
      ) {
        if (!selectedMountOption) {
          newMissingFields.push("pick a mount");
        } else if (selectedMountOption === "A") {
          if (!selectedFasciaInches) {
            newMissingFields.push("fascia inches");
          }
        }
        if (!stepDownFeet) {
          newMissingFields.push("stepdown feet");
        }

        if (!stepDownInches) {
          newMissingFields.push("stepdown inches");
        }
      }
      for (const key in personalInfo) {
        if (key === "firstName" && personalInfo[key] === "") {
          continue;
        }
        if (key === "purchaseOrder" && personalInfo[key] === "") {
          continue;
        }
        if (!personalInfo[key]) {
          newMissingFields.push(key);
        }
      }
      if (personalInfo["email"] && !isValidEmail) {
        newMissingFields.push("email");
      }
      if (locations && Object.keys(locations).length > 0) {
        if (!selectedLocation) {
          newMissingFields.push("location");
        }
      }
    }

    if (newMissingFields.length === 0) {
      const data = {
        PergolasData: {
          selectedTrimOption,
          selectedOperableOption,
          selectedBiHexOption,
          selectedExteriorOption,
          selectedPostStyleOption,
          selectedActuatorOption,
          selectedAttachedOption,
          selectedPermitOption,
          selectedFoundationOption,
          selectedKneeWallOption,
          selectedMountOption,
          personalInfo,
          selectedExistingFoundationOption,
          selectedNewFoundationOption,
          selectedRoofLoadChange,
          selectedWindLoadChange,
          widthFeet,
          widthInches,
          lengthFeet,
          lengthInches,
          stepDownInches,
          stepDownFeet,
          selectedPostHeight,
          selectedKneeWallFeet,
          selectedKneeWallInches,
          selectedFasciaInches,
          gradeHeightInches,
          gradeHeightFeet,
          selectedBeamOption,
          additionalInfo,
          selectedRafterColor,
          selectedGutterOption,
          motorCityPergolaSize,
          initialSteelPiers,
          selectedLocation,
        },
      };

      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: "TEMOAuth",
        },
      });
      const jsonData = JSON.stringify(data);

      fetch("/api/OrderingPergolas/SubmitData", {
        method: "POST",
        body: jsonData,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            // return response.json().then((data) => {
            //   setCurrentCADImagesFiles(data.fileName);
            setSubmitIsLoading(false);
            setEndMessage(<SuccessMessage />);
            setSentCorrectly(true);
            // });
          } else if (response.status === 999) {
            setSubmitIsLoading(false);
            setEndMessage(<HoldErrorMessage />);
          } else if (response.status === 998) {
            setSubmitIsLoading(false);
            setEndMessage(<RepErrorMessage />);
          } else if (response.status === 997) {
            setSubmitIsLoading(false);
            setEndMessage(<PatioLockMessage />);
          } else {
            setSubmitIsLoading(false);
            setEndMessage(<ErrorMessage />);
          }
          setShowEndMessage(true);
        })
        .catch((error) => {
          console.error("Error sending data:", error);
          setSubmitIsLoading(false);
          setEndMessage(<ErrorMessage />);
          setShowEndMessage(true);
        });
      closeModal();
    } else {
      setSubmitIsLoading(false);
      setFormattedMissingFields(formattedMissingFields);
      closeModal();
    }
  };

  return (
    <div className="popupwindow submit-stuff">
      <div style={{ display: "flex", flexDirection: "column" }}>
        {showEndMessage && <div>{endMessage}</div>}
        {showMissingFields && formattedMissingFields.length > 0 && (
          <MissingFieldsBox missingFields={formattedMissingFields} />
        )}
        {/* <DrawingOverlaySelection
          currentCADImagesFiles={currentCADImagesFiles}
          drawingImages={drawingImages}
          setDrawingImages={setDrawingImages}
          setIsDrawingOverlayOpen={setIsDrawingOverlayOpen}
          sentCorrectly={sentCorrectly}
          isDrawingOverlayOpen={isDrawingOverlayOpen}
        /> */}
        {submitIsLoading && <div>Loading...</div>}
      </div>
      <form id="submitbutton">
        {sentCorrectly !== true && submitIsLoading !== true && (
          <>
            <span className="realredasterisk">
              <div className="fontsizeforinputsrow" style={{ fontSize: "2vh" }}>
                Any&nbsp;Area&nbsp;With&nbsp;This&nbsp;&#10088;
                <b>
                  <span className="redasterisk">&#10033;</span>
                </b>
                &#10089;&nbsp;Is
              </div>
              <div
                className="fontsizeforinputsrow"
                style={{ textAlign: "center", fontSize: "2vh" }}
              >
                <u>
                  <b>REQUIRED</b>
                </u>
                &nbsp;Before&nbsp;Submitting
              </div>
            </span>
            <input
              id="submit"
              type="submit"
              className="showpdfbuttons submitbutton"
              onClick={openModal}
              value="Send to TEMO"
            />
          </>
        )}
      </form>
      {showModal && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            height: "100%",
            width: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(34, 43, 43, .9)",
          }}
        >
          <div
            className="popupwindow"
            style={{
              height: "86vh",
              width: "45vw",
              display: "flex",
              flexDirection: "column",
              backgroundImage: "linear-gradient(to bottom, #09b9e148, #09b8e1)",
            }}
          >
            <SimpleBar style={{ maxHeight: "85vh" }}>
              <div
                style={{
                  height: "10%",
                  backgroundColor: "#9ecb6a",
                  top: "0",
                  marginTop: "0",
                  border: "1px solid black",
                }}
              >
                <h5>Are you sure you want to submit?</h5>
                <h5>These are your selections:</h5>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "white",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    margin: "0",
                    width: "50%",
                    height: "100%",
                  }}
                >
                  {personalInfo.lastName && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Last Name:</p>
                      <p className="modalrowright">{personalInfo.lastName}</p>
                    </div>
                  )}
                  {personalInfo.firstName && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">First Name:</p>
                      <p className="modalrowright">{personalInfo.firstName}</p>
                    </div>
                  )}
                  {personalInfo.address && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Address:</p>
                      <p className="modalrowright">{personalInfo.address}</p>
                    </div>
                  )}
                  {personalInfo.city && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">City:</p>
                      <p className="modalrowright">{personalInfo.city}</p>
                    </div>
                  )}
                  {personalInfo.stateProvince && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">State/Province:</p>
                      <p className="modalrowright">
                        {personalInfo.stateProvince}
                      </p>
                    </div>
                  )}
                  {personalInfo.zip && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Zip:</p>
                      <p className="modalrowright">{personalInfo.zip}</p>
                    </div>
                  )}
                  {personalInfo.purchaseOrder && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Purchase Order:</p>
                      <p className="modalrowright">
                        {personalInfo.purchaseOrder}
                      </p>
                    </div>
                  )}
                  {selectedAttachedOption && (
                    <div className="modalinfoleft">
                      <p
                        className="modalrowleft"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        Attached or Free-Standing:
                      </p>
                      <p className="modalrowright">{selectedAttachedOption}</p>
                    </div>
                  )}
                  {selectedOperableOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Pergola Type:</p>
                      <p className="modalrowright">{selectedOperableOption}</p>
                    </div>
                  )}
                  {selectedTrimOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Frame Color:</p>
                      <p className="modalrowright">{selectedTrimOption}</p>
                    </div>
                  )}
                  {selectedRafterColor && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Louver Color:</p>
                      <p className="modalrowright">{selectedRafterColor}</p>
                    </div>
                  )}
                  {selectedPostStyleOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Post Style:</p>
                      <p className="modalrowright">{selectedPostStyleOption}</p>
                    </div>
                  )}
                  {selectedPostHeight && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Height of Post:</p>
                      <p className="modalrowright">{selectedPostHeight}</p>
                    </div>
                  )}
                  {selectedExteriorOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Exterior Style:</p>
                      <p className="modalrowright">{selectedExteriorOption}</p>
                    </div>
                  )}
                  {selectedBeamOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Beam:</p>
                      <p className="modalrowright">{selectedBeamOption}</p>
                    </div>
                  )}
                  {lengthFeet && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Feet of Length:</p>
                      <p className="modalrowright">{lengthFeet}</p>
                    </div>
                  )}
                  {lengthInches && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Inches of Length:</p>
                      <p className="modalrowright">{lengthInches}</p>
                    </div>
                  )}
                  {widthFeet && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Feet of Width:</p>
                      <p className="modalrowright">{widthFeet}</p>
                    </div>
                  )}
                  {widthInches && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Inches of Width:</p>
                      <p className="modalrowright">{widthInches}</p>
                    </div>
                  )}
                  {selectedBiHexOption && (
                    <div className="modalinfoleft">
                      <p className="modalrowleft">Bi-Hex Tool Option:</p>
                      <p className="modalrowright">{selectedBiHexOption}</p>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "right",
                    margin: "0",
                    width: "50%",
                  }}
                >
                  {selectedLocation && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Location:</p>
                      <p className="modalrowright">{selectedLocation}</p>
                    </div>
                  )}
                  {selectedActuatorOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Cable Option:</p>
                      <p className="modalrowright">{selectedActuatorOption}</p>
                    </div>
                  )}
                  {personalInfo.email && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Email:</p>
                      <p className="modalrowright">{personalInfo.email}</p>
                    </div>
                  )}
                  {personalInfo.sender && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Sender:</p>
                      <p className="modalrowright">{personalInfo.sender}</p>
                    </div>
                  )}
                  {selectedPermitOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Drawing Package:</p>
                      <p className="modalrowright">{selectedPermitOption}</p>
                    </div>
                  )}
                  {selectedFoundationOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Foundation:</p>
                      <p className="modalrowright">
                        {selectedFoundationOption}
                      </p>
                    </div>
                  )}
                  {selectedExistingFoundationOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Existing Concrete:</p>
                      <p className="modalrowright">
                        {selectedExistingFoundationOption}
                      </p>
                    </div>
                  )}
                  {selectedNewFoundationOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">New Concrete:</p>
                      <p className="modalrowright">
                        {selectedNewFoundationOption}
                      </p>
                    </div>
                  )}
                  {selectedRoofLoadChange && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Roof Load:</p>
                      <p className="modalrowright">{selectedRoofLoadChange}</p>
                    </div>
                  )}
                  {selectedWindLoadChange && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Wind Load:</p>
                      <p className="modalrowright">{selectedWindLoadChange}</p>
                    </div>
                  )}
                  {selectedMountOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Mount Option:</p>
                      <p className="modalrowright">{selectedMountOption}</p>
                    </div>
                  )}
                  {selectedFasciaInches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Soffit Depth:</p>
                      <p className="modalrowright">{selectedFasciaInches}</p>
                    </div>
                  )}
                  {stepDownInches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Inches of Step Down:</p>
                      <p className="modalrowright">{stepDownInches}</p>
                    </div>
                  )}
                  {stepDownFeet && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Feet of Step Down:</p>
                      <p className="modalrowright">{stepDownFeet}</p>
                    </div>
                  )}
                  {gradeHeightInches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Inches of Grade Height:</p>
                      <p className="modalrowright">{gradeHeightInches}</p>
                    </div>
                  )}
                  {gradeHeightFeet && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Feet of Grade Height:</p>
                      <p className="modalrowright">{gradeHeightFeet}</p>
                    </div>
                  )}
                  {selectedKneeWallOption && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Knee Wall Option:</p>
                      <p className="modalrowright">{selectedKneeWallOption}</p>
                    </div>
                  )}
                  {selectedKneeWallFeet && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Feet of Knee Wall:</p>
                      <p className="modalrowright">{selectedKneeWallFeet}</p>
                    </div>
                  )}
                  {selectedKneeWallInches && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Inches of Knee Wall:</p>
                      <p className="modalrowright">{selectedKneeWallInches}</p>
                    </div>
                  )}
                  {motorCityPergolaSize && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Size:</p>
                      <p className="modalrowright">{motorCityPergolaSize}</p>
                    </div>
                  )}
                  {initialSteelPiers && (
                    <div className="modalinforight">
                      <p className="modalrowleft">Steel Piers:</p>
                      <p className="modalrowright">{initialSteelPiers}</p>
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  height: "fit-content",
                  marginTop: "1%",
                  marginBottom: "5%",
                }}
              >
                <div>
                  {Array.from({ length: 5 }).map((_, index) => (
                    <textarea
                      key={index}
                      value={additionalInfo[index] || ""}
                      onChange={(e) =>
                        handleAdditionalInfoChange(index, e.target.value)
                      }
                      placeholder={`Special Instructions ${index + 1}`}
                      rows="1"
                      maxLength="48"
                      style={{ width: "94%", margin: "-.4%" }}
                    />
                  ))}
                </div>
                <div>
                  <b>Please Email Attachments To: </b>
                  <br></br>
                  <b>Steve Salter:&nbsp;</b>
                  <a href="mailto:ssalter@temoinc.com">ssalter@temoinc.com</a>
                  &nbsp;&nbsp;
                  <b>Phil Taravella:&nbsp;</b>
                  <a href="mailto:ptaravella@temoinc.com">
                    ptaravella@temoinc.com
                  </a>
                  <br></br>
                  <br></br>
                </div>
                <button
                  className="showpdfbuttons"
                  style={{
                    width: "fit-content",
                    height: "auto",
                    float: "inline-start",
                    marginLeft: "20%",
                  }}
                  onClick={() => {
                    setSubmitIsLoading(false);
                    closeModal();
                  }}
                >
                  No
                </button>
                {formattedMissingFields.length === 0 && (
                  <button
                    className="showpdfbuttons"
                    style={{
                      width: "fit-content",
                      height: "auto",
                      float: "inline-end",
                      marginRight: "20%",
                    }}
                    onClick={handleSubmit}
                  >
                    Yes
                  </button>
                )}
              </div>
            </SimpleBar>
          </div>
        </div>
      )}
    </div>
  );
};

SubmitButton.propTypes = {
  selectedTrimOption: PropTypes.string,
  selectedOperableOption: PropTypes.string,
  selectedBiHexOption: PropTypes.string,
  selectedExteriorOption: PropTypes.string,
  selectedPostStyleOption: PropTypes.string,
  selectedActuatorOption: PropTypes.string,
  selectedAttachedOption: PropTypes.string,
  selectedPermitOption: PropTypes.string,
  selectedFoundationOption: PropTypes.string,
  selectedKneeWallOption: PropTypes.string,
  selectedMountOption: PropTypes.string,
  personalInfo: PropTypes.object,
  selectedExistingFoundationOption: PropTypes.string,
  selectedNewFoundationOption: PropTypes.string,
  gradeHeightInches: PropTypes.string,
  gradeHeightFeet: PropTypes.string,
  stepDownFeet: PropTypes.string,
  stepDownInches: PropTypes.string,
  selectedRoofLoadChange: PropTypes.string,
  selectedWindLoadChange: PropTypes.string,
  widthFeet: PropTypes.string,
  widthInches: PropTypes.string,
  lengthFeet: PropTypes.string,
  lengthInches: PropTypes.string,
  selectedPostHeight: PropTypes.string,
  selectedKneeWallInches: PropTypes.string,
  selectedKneeWallFeet: PropTypes.string,
  selectedFasciaInches: PropTypes.string,
  onRedBorderCheck: PropTypes.func,
  selectedBeamOption: PropTypes.string,
  selectedRafterColor: PropTypes.string,
  selectedGutterOption: PropTypes.string,
  selectedLength: PropTypes.string,
  selectedWidth: PropTypes.string,
  initialSteelPiers: PropTypes.string,
  isValidEmail: PropTypes.bool,
  selectedLocation: PropTypes.string,
  locations: PropTypes.object,
  setCurrentCADImagesFiles: PropTypes.func,
  currentCADImagesFiles: PropTypes.string,
  drawingImages: PropTypes.array,
  setDrawingImages: PropTypes.func,
};

export default SubmitButton;
